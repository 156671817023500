import { types } from "../types/types";
import { adapterAddSimulationsInSimulationsByFam } from "../adapters/adapterAddSimulationsInSimulationsByFam";
import { adapterRemoveSimulationsInSimulationsByFam } from "../adapters/adapterRemoveSimulationsInSimulationsByFam";

export const initialState = {
    offers: [],
    simulations: [],
    additionalProducts: [],
    isCalculating: false,
    isLoading: false,
    comparableOffers: [],
    maxOfferToCompare: 5,
    selected: null,
    loading: true,
    error: null,
    modalDetailSelected: null,
    showComparatorModal: false,
    isSendingOffers: false,
    isSimulatorOfferSended: null,
    isMantenimientoOfferSended: null,
    isSeguroAutomovilOfferSended: null,
    isDownloadingCreditRequestDocument: false,
    creditRequestDocumentCode: null,
};

const offerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.offerSetOffers:
            return {
                ...state,
                offers: action.payload,
            };
        case types.offerSetSimulations:
            return {
                ...state,
                simulations: [...action.payload],
            };
        case types.offerSetAdditionalProducts:
            return {
                ...state,
                additionalProducts: [...action.payload],
            };

        case types.offerSetModalDetailSelected:
            return {
                ...state,
                modalDetailSelected: action.payload ? { ...action.payload } : null,
            };
        case types.offerAddSimulation:
            return {
                ...state,
                simulations: adapterAddSimulationsInSimulationsByFam([...state.simulations], action.payload),
            };
        case types.offerSetIsCalculating:
            return {
                ...state,
                isCalculating: action.payload,
            };
        case types.offerSetIsLoading:
            return {
                ...state,
                isLoading: action.payload,
            };
        case types.offerSetIsSendingOffers:
            return {
                ...state,
                isSendingOffers: action.payload,
            };
        case types.offerRemoveSimulation:
            return {
                ...state,
                simulations: adapterRemoveSimulationsInSimulationsByFam([...state.simulations], action.payload),
            };
        case types.offerSetShowComparatorModal:
            return {
                ...state,
                showComparatorModal: action.payload,
            };
        case types.offerAddComparableOffer:
            return {
                ...state,
                comparableOffers: [...state.comparableOffers, action.payload],
            };
        case types.offerRemoveComparableOffer: {
            const newComparableOffers = [
                ...state.comparableOffers.filter((item) => !action.payload.map((a) => a.Code).includes(item.Code)),
            ];
            return {
                ...state,
                comparableOffers: newComparableOffers,
            };
        }
        case types.offerSetIsSimulatorOfferSended:
            return {
                ...state,
                isSimulatorOfferSended: action.payload,
            };
        case types.offerSetIsMantenimientoOfferSended:
            return {
                ...state,
                isMantenimientoOfferSended: action.payload,
            };
        case types.offerSetIsSeguroAutomovilOfferSended:
            return {
                ...state,
                isSeguroAutomovilOfferSended: action.payload,
            };
        case types.offerSetIsDownloadingCreditRequestDocument:
            return {
                ...state,
                isDownloadingCreditRequestDocument: action.payload,
            };
        case types.offerSetCreditRequestDocumentCode:
            return {
                ...state,
                creditRequestDocumentCode: action.payload,
            };
        default:
            return state;
    }
};

export default offerReducer;
