export const checkIfIsNIE = (number) => {
    if (number != undefined) {
        const patternNIE = /^[XYZ]\d{7}[A-Z]$/;

        if (patternNIE.test(number.toUpperCase())) {
            // NIE validation algorithm
            const nie = number.replace(/^[X]/, "0").replace(/^[Y]/, "1").replace(/^[Z]/, "2");
            const letters = "TRWAGMYFPDXBNJZSQVHLCKE";
            const letter = number.charAt(8).toUpperCase();
            const dni = parseInt(nie.substring(0, 8));
            return letter === letters.charAt(dni % 23);
        }
    }
    return false;
};
