import moment from "moment";
const region = "es";
moment.locale(region.substring(0, 2));
export const validateDriversLicenceDate = (birthday, DriversLicenceDate) => {
    var dateBirthDay = moment(birthday,"DD/MM/YYYY");
    var driversLicenceMinAge = dateBirthDay.add(18,"y");
    var dateDriversLicence = moment(DriversLicenceDate,"DD/MM/YYYY");
    if (driversLicenceMinAge > moment()) {
        return false;
    } else if (driversLicenceMinAge > dateDriversLicence) {
        return false;
    } else if (dateDriversLicence > moment()) {
        return false;
    }
    return true;
};
