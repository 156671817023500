import { useState } from "react";
import { useSummary } from "./useSummary";
import { useStepWizard } from "./useStepWizard";
import { validateClientInfo } from "../validators/validateClientInfo";
import { CalculatorTypeEnum, StepsNameEnum } from "../types/enums";
import { useGlobals } from "./useGlobals";
import { useNavigate } from "react-router-dom";
import Notification, { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../class/Notification";
import { addNotification } from "../actions/notificationAction";
import { useDispatch } from "react-redux";
import { store } from "../store/store";

export const useWizard = () => {
    const [isDirty, setIsDirty] = useState(false);
    const { calculatorType } = useGlobals();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { selectedClientStandAlone } = useSummary();
    const { steps, setSteps, stepSelected, setStepSelected, showModalInfoStandAlone, setShowModalInfoStandAlone } =
        useStepWizard();

    const hasSelectedServices = () => {
        const { selectedMantenimentoStandAlone, selectedSeguroAutoStandAlone } = store.getState().summary;

        return selectedMantenimentoStandAlone || selectedSeguroAutoStandAlone?.SeguroContratado;
    };

    const checkClientInfoStep = () => {
        var index = steps.findIndex((x) => x.Name === StepsNameEnum.RESUMEN);
        var indexDatosClient = steps.findIndex((x) => x.Name === StepsNameEnum.DATOSCLIENTE);

        if (hasSelectedServices() && indexDatosClient === -1) {
            steps.splice(index, 0, { Name: StepsNameEnum.DATOSCLIENTE });
            setSteps(steps);
            return;
        }

        if (!hasSelectedServices() && indexDatosClient !== -1) {
            steps.splice(indexDatosClient, 1);
            setSteps(steps);
            return;
        }
    };

    const backStep = () => {
        var index = steps.findIndex((x) => x.Name === stepSelected.name);
        if (
            index === 0 &&
            (calculatorType === CalculatorTypeEnum.FINANCE || calculatorType === CalculatorTypeEnum.RENTING)
        ) {
            navigate("/simulator");
            return;
        }
        index--;

        checkClientInfoStep();

        setStepSelected({
            index,
            name: steps[index].Name,
        });
    };

    const nextStep = () => {
        if (stepSelected.name === StepsNameEnum.DATOSCLIENTE) {
            setIsDirty(true);
            if (!validateClientInfo(selectedClientStandAlone)) {
                const notification = new Notification.fromObject({
                    Description: "Datos a rojo están incorrectos!",
                    Timeout: 3000,
                    Type: TYPE_NOTIFICACION.TOAST,
                    Severity: SEVERITY_NOTIFICACION.ERROR,
                });
                dispatch(addNotification(notification));
                return;
            }
            setIsDirty(false);
        }

        var index = steps.findIndex((x) => x.Name === stepSelected.name);
        index++;

        checkClientInfoStep();

        setStepSelected({
            index,
            name: steps[index].Name,
        });
    };

    const validateDownloadCheckbox = (download) => {
        setIsDirty(true);

        if (!download) {
            const notification = new Notification.fromObject({
                Description: "Por favor, seleccione la casilla 'Entrega Nota Previa Informativa del Mediador'.",
                Timeout: 3000,
                Type: TYPE_NOTIFICACION.TOAST,
                Severity: SEVERITY_NOTIFICACION.ERROR,
            });

            dispatch(addNotification(notification));

            return false;
        }

        setIsDirty(false);

        return true;
    };

    return {
        steps,
        stepSelected,
        isDirty,
        setSteps,
        backStep,
        nextStep,
        setStepSelected,
        showModalInfoStandAlone,
        setShowModalInfoStandAlone,
        validateDownloadCheckbox,
    };
};
