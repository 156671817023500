import { types } from "../types/types";

export const initialState = {
    steps: [],
    alreadyEnterInWizard: false,
};

export const wizardReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.wizardSetSteps:
            return {
                ...state,
                steps: action.payload,
            };
        case types.wizardSetAlreadyEnterInWizard:
            return {
                ...state,
                alreadyEnterInWizard: action.payload,
            };
        default:
            return state;
    }
};
