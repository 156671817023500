import { useEffect } from "react";
import { useState } from "react";
import { useGlobals } from "./useGlobals";
import { CalculatorTypeEnum, StepsNameEnum } from "../types/enums";
import { getAndSetAllCountries, getAndSetAllStreetTypes } from "../actions/locationAction";
import { useDispatch } from "react-redux";
import { useSummary } from "./useSummary";

export const useStepWizard = () => {
  const [steps, setSteps] = useState([]);
  const [stepSelected, setStepSelected] = useState(null);
  const [showModalInfoStandAlone, setShowModalInfoStandAlone] = useState(false);

  const { hasAccessToLongDrive, calculatorType } = useGlobals();
  const dispatch = useDispatch();

  const { selectedResumeOffers } = useSummary();

  useEffect(() => {
    if (
      !hasAccessToLongDrive ||
      calculatorType === CalculatorTypeEnum.RENTING ||
      process.env.REACT_APP_SHOW_STANDALONE !== "true"
    ) {
      setSteps([
        {
          Name: StepsNameEnum.RESUMEN,
        },
      ]);
      setStepSelected({
        index: 0,
        name: StepsNameEnum.RESUMEN,
      });
      return;
    }
    dispatch(getAndSetAllCountries());
    dispatch(getAndSetAllStreetTypes());
    if (calculatorType === CalculatorTypeEnum.FINANCE) {
      wizardStepsToFinance();
      return;
    }
    if (calculatorType === CalculatorTypeEnum.MANTENIMIENTOSTANDALONE) {
      wizardStepsToMantenimientoStandAlone();
      return;
    }
    if (calculatorType === CalculatorTypeEnum.SEGUROAUTOSTANDALONE) {
      wizardStepsToSeguroAutoStandAlone();
      return;
    }
    if (calculatorType === CalculatorTypeEnum.ALLSTANDALONE) {
      wizardStepsToAllStandAlone();
      return;
    }
  }, []);

  const wizardStepsToFinance = () => {
    let Steps = [];
    const financeOffersSelected = selectedResumeOffers?.filter((x) => x.OfferType?.Key == "FI");
    if (financeOffersSelected.length > 0) {
      if (
        //TODO:CHANGE TO MT
        financeOffersSelected[0].AdditionalProducts?.Servicios.find((x) => x.CodigoServicio === "MT") === undefined
      ) {
        Steps.push({
          Name: StepsNameEnum.MANTENIMIENTO,
        });
        setShowModalInfoStandAlone(true);
      }
      if (
        //TODO:CHANGE TO SA
        financeOffersSelected[0].AdditionalProducts?.Servicios.find((x) => x.CodigoServicio === "SA") === undefined
      ) {
        Steps.push({
          Name: StepsNameEnum.SEGUROAUTOMOVIL,
        });
        setShowModalInfoStandAlone(true);
      }
    }

    Steps.push({
      Name: StepsNameEnum.RESUMEN,
    });

    setSteps(Steps);

    setStepSelected({
      index: 0,
      name: Steps[0].Name,
    });
  };

  const wizardStepsToMantenimientoStandAlone = () => {
    setSteps([
      {
        Name: StepsNameEnum.MANTENIMIENTO,
      },
      {
        Name: StepsNameEnum.RESUMEN,
      },
    ]);
    setStepSelected({
      index: 0,
      name: StepsNameEnum.MANTENIMIENTO,
    });
  };

  const wizardStepsToSeguroAutoStandAlone = () => {
    setSteps([
      {
        Name: StepsNameEnum.SEGUROAUTOMOVIL,
      },
      {
        Name: StepsNameEnum.RESUMEN,
      },
    ]);
    setStepSelected({
      index: 0,
      name: StepsNameEnum.SEGUROAUTOMOVIL,
    });
  };

  const wizardStepsToAllStandAlone = () => {
    setSteps([
      {
        Name: StepsNameEnum.MANTENIMIENTO,
      },
      {
        Name: StepsNameEnum.SEGUROAUTOMOVIL,
      },
      {
        Name: StepsNameEnum.RESUMEN,
      },
    ]);
    setStepSelected({
      index: 0,
      name: StepsNameEnum.MANTENIMIENTO,
    });
  };

  return { steps, setSteps, stepSelected, setStepSelected, showModalInfoStandAlone, setShowModalInfoStandAlone };
};
