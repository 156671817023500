import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Layout, Form } from "@vwfs-bronson/bronson-react";
// import Notification, { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../../../class/Notification";
// import { addNotification } from "../../../actions/notificationAction";

import { useForm } from "../../../hooks/useForm";
import { setSelectedClientStandAlone } from "../../../actions/summaryAction";
import { Client } from "./Client/Client";
import { Direction } from "./Direction/Direction";
import { OtherInfo } from "./OtherInfo/OtherInfo";
import { useSummary } from "../../../hooks/useSummary";
import { useClient } from "../../../hooks/useClient";

export const Clientinfo = () => {
    const dispatch = useDispatch();
    const { client } = useClient();
    const { selectedClientStandAlone, selectedSeguroAutoStandAlone } = useSummary();
    const initFormData = {
        Sex: "V",
        Name: "",
        Surname1: "",
        Surname2: "",
        NIF: selectedSeguroAutoStandAlone?.Tomador?.NIF ?? "",
        TipoDocumento: "",
        PersonaFisicaJuridica: client?.Class?.Key ?? "F",
        Nacionality: selectedSeguroAutoStandAlone?.Tomador?.Nacionality ?? "ESP",
        PhoneNumber: "",
        Email: "",
        EmailConfirmation: "",
        StreetType: "",
        StreetName: "",
        StreetNumber: "",
        StreetBlock: "",
        StreetStairs: "",
        StreetFloor: "",
        StreetDoor: "",
        StreetComplement: "",
        ZipCode: selectedSeguroAutoStandAlone?.Tomador?.CodigoPostal ?? "",
        IdProvince: selectedSeguroAutoStandAlone?.Tomador?.IdProvince ?? "",
        Province: selectedSeguroAutoStandAlone?.Tomador?.Province ?? "",
        IdPoblacion: selectedSeguroAutoStandAlone?.Tomador?.IdPoblacion ?? "",
        Poblacion: selectedSeguroAutoStandAlone?.Tomador?.Poblacion ?? "",
        AssociatedPersonToOffer: "",
        GRPD: false,
    };

    const [formValuesClient, setHandleOnChangeClient, , setValues] = useForm(
        selectedClientStandAlone === null ? initFormData : selectedClientStandAlone
    );

    useEffect(() => {
        dispatch(setSelectedClientStandAlone(formValuesClient));
    }, [formValuesClient]);

    return (
        <Layout.Item>
            <Form>
                <React.Fragment key=".0">
                    <Client
                        formValuesClient={formValuesClient}
                        setHandleOnChangeClient={setHandleOnChangeClient}
                        setValues={setValues}
                    ></Client>
                    <Direction
                        formValuesClient={formValuesClient}
                        setHandleOnChangeClient={setHandleOnChangeClient}
                        setValues={setValues}
                    ></Direction>
                    <OtherInfo
                        formValuesClient={formValuesClient}
                        setHandleOnChangeClient={setHandleOnChangeClient}
                    ></OtherInfo>
                </React.Fragment>
            </Form>
        </Layout.Item>
    );
};
