import React, { Suspense } from "react";
import { GeneralRoutes } from "./routes/GeneralRoutes";

import moment from "moment";
import "moment/locale/es";
import { HashRouter } from "react-router-dom";
moment().locale("es");

import { Spinner } from "@vwfs-bronson/bronson-react";

export default function App() {
    return (
        <Suspense fallback={<Spinner fullPage></Spinner>}>
            <HashRouter>
                <GeneralRoutes>{(routeProps) => <GeneralRoutes routeProps={routeProps} />}</GeneralRoutes>
            </HashRouter>
        </Suspense>
    );
}
