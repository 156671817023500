import { types } from "../types/types";

export const initialState = {
    addicionalProdsList: null,
    additionalProducts: {},
    openTab: "",
    showModalAddProd: false,
    mantenimientoStandAlone: null,
    seguroAutomovilStandAlone: null,
};

const addictionalProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.addictionalProductsSetAdditionalProductsList:
            return {
                ...state,
                addicionalProdsList: action.payload,
            };
        case types.addictionalProductsSetAdditionalProducts:
            return {
                ...state,
                additionalProducts: action.payload,
            };
        case types.addictionalProductsSetOpenTab:
            return {
                ...state,
                openTab: action.payload,
            };
        case types.addictionalProductsShowModalAddProd:
            return {
                ...state,
                showModalAddProd: action.payload,
            };
        case types.addictionalProductsMantenimientoStandAlone:
            return {
                ...state,
                mantenimientoStandAlone: action.payload,
            };
        case types.addictionalProductsSeguroAutomovilStandAlone:
            return {
                ...state,
                seguroAutomovilStandAlone: action.payload,
            };
        default:
            return state;
    }
};

export default addictionalProductsReducer;
