import React, { useEffect } from "react";

import { Spinner } from "@vwfs-bronson/bronson-react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { redirectCheckout } from "../actions/globalAction";

export const CheckoutPage = () => {
    const dispatch = useDispatch();
    const { token } = useParams();

    useEffect(() => {
        if (token) dispatch(redirectCheckout(token));
    }, [token, dispatch]);

    return (
        <Spinner fullPage>
            <p>Te estamos redirigiendo...</p>
        </Spinner>
    );
};
