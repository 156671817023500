import React from "react";

import {
    Wizard,
    Layout,
    Form,
    Fieldset,
    Heading,
    FormField,
    RadioButtonGroup,
    RadioButton,
    ButtonContainer,
    Checkbox,
    Input,
    DatePicker,
    Button,
} from "@vwfs-bronson/bronson-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { MantenimientoStandAlone } from "../MantenimientoStandAlone";

import moment from "moment";

import "moment/locale/es";
import { useContext } from "react";
import { WizardConext } from "../../../../contexts/WizardContext";
import Notification, { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../../../../class/Notification";
import { addNotification } from "../../../../actions/notificationAction";

const region = "es";
moment.locale(region);

export const MantenimientoStandAloneVO = () => {
    const dispatch = useDispatch();
    const [isDirty, setIsDirty] = useState(false);
    const [validation, setValidation] = useState({
        kilometers: false,
        LastMaintenance: false,
    });
    const { OutOfWindowAction } = useContext(WizardConext);
    const [stepSelected, setStepSelected] = useState("FueraVentana");

    const handleNext = () => {
        if (OutOfWindowAction.outOfWindow || OutOfWindowAction.doesntHaveMaintenance) {
            OutOfWindowAction.setValuesOutOfWindow({
                OutOfWindow: !OutOfWindowAction.outOfWindow,
                KmLastMaintenance: 0,
                LastMaintenance: "",
            });
        } else {
            if (!OutOfWindowAction.formOutOfWindow.KmLastMaintenance || !OutOfWindowAction.lastMaintenance) {
                setIsDirty(true);
                setValidation({
                    kilometers: OutOfWindowAction.formOutOfWindow.KmLastMaintenance ? true : false,
                    LastMaintenance: OutOfWindowAction.lastMaintenance ? true : false,
                });

                const notification = new Notification.fromObject({
                    Description: "Datos a rojo están incorrectos!",
                    Timeout: 3000,
                    Type: TYPE_NOTIFICACION.TOAST,
                    Severity: SEVERITY_NOTIFICACION.ERROR,
                });
                dispatch(addNotification(notification));
                return;
            } else
                setValidation({
                    kilometers: true,
                    LastMaintenance: true,
                });

            OutOfWindowAction.setValuesOutOfWindow({
                ...OutOfWindowAction.formOutOfWindow,
                OutOfWindow: !OutOfWindowAction.outOfWindow,
                LastMaintenance: moment(OutOfWindowAction.lastMaintenance, "DD/MM/YYYY").format("YYYY/MM/DD"),
            });
        }

        setStepSelected("Mantenimientos");
    };

    const handleBack = () => {
        dispatch(setStepSelected("FueraVentana"));
    };

    return (
        <>
            <Wizard collapseBreakpoint="s" collapsedCounter="1/2" collapsedText="Current Step" noInteraction>
                {stepSelected === "FueraVentana" ? (
                    <>
                        <Wizard.Item key="step_datos_fueraVentana" active>
                            Fuera Ventana
                        </Wizard.Item>
                        <Wizard.Item key="step_mantenimiento">Selección Mantenimiento</Wizard.Item>
                    </>
                ) : (
                    <>
                        <Wizard.Item key="step_datos_fueraVentana" done>
                            Fuera Ventana
                        </Wizard.Item>
                        <Wizard.Item key="step_mantenimiento" active>
                            Selección Mantenimiento
                        </Wizard.Item>
                    </>
                )}
            </Wizard>
            <Layout.Item>
                <ButtonContainer>
                    {stepSelected === "Mantenimientos" && (
                        <Button onClick={() => handleBack()} link small icon="semantic-back">
                            Editar fuera ventana
                        </Button>
                    )}
                </ButtonContainer>
            </Layout.Item>
            {stepSelected === "FueraVentana" && (
                <Layout.Item>
                    <Form>
                        <React.Fragment key=".0">
                            <Fieldset.Row>
                                <Layout className="u-mt-xsmall">
                                    <Layout.Item default="2/3">
                                        <Heading level={6} className="u-text-left">
                                            Ha realizado la intervención correspondiente a los 60.000 km / 4 años en un
                                            plazo inferior a 15 días *
                                        </Heading>
                                    </Layout.Item>
                                    <Layout.Item default="1/3" xs="1/1">
                                        <FormField labelElement="div" staticLabel type="other">
                                            <RadioButtonGroup className="u-float-right">
                                                <RadioButton
                                                    checked={OutOfWindowAction.outOfWindow}
                                                    name="outOfWindow"
                                                    onChange={() => OutOfWindowAction.setOutOfWindow(true)}
                                                >
                                                    Sí
                                                </RadioButton>
                                                <RadioButton
                                                    checked={!OutOfWindowAction.outOfWindow}
                                                    name="outOfWindow"
                                                    onChange={() => OutOfWindowAction.setOutOfWindow(false)}
                                                >
                                                    No
                                                </RadioButton>
                                            </RadioButtonGroup>
                                        </FormField>
                                    </Layout.Item>
                                    {!OutOfWindowAction.outOfWindow && (
                                        <>
                                            <Layout.Item default="1/1" xs="1/1">
                                                <Checkbox
                                                    name="checkbox-demo"
                                                    defaultChecked={OutOfWindowAction.doesntHaveMaintenance}
                                                    onChange={(e) =>
                                                        OutOfWindowAction.setDoesntHaveMaintenance(e.target.checked)
                                                    }
                                                >
                                                    Según Pand de Asistencia Técnica no correspondia mantenimiento
                                                </Checkbox>
                                            </Layout.Item>
                                            <Layout.Item default="1/1" xs="1/1">
                                                <Heading level={6} className="u-text-left">
                                                    Datos última intervención
                                                </Heading>
                                                <Layout>
                                                    <Layout.Item default="1/2" xs="1/1">
                                                        <FormField
                                                            labelForId="KmLastMaintenance"
                                                            labelText="Kilómetros última intervención"
                                                            type="input"
                                                        >
                                                            <Input
                                                                id="KmLastMaintenance"
                                                                name="KmLastMaintenance"
                                                                placeholder="Kilómetros"
                                                                error={!validation.kilometers && isDirty}
                                                                value={
                                                                    OutOfWindowAction.formOutOfWindow.KmLastMaintenance
                                                                }
                                                                onChange={(e) =>
                                                                    OutOfWindowAction.setHandleOnChangeOutOfWindow(e)
                                                                }
                                                                disabled={OutOfWindowAction.doesntHaveMaintenance}
                                                            />
                                                        </FormField>
                                                    </Layout.Item>
                                                    <Layout.Item default="1/2" xs="1/1">
                                                        <FormField
                                                            labelForId="fecha"
                                                            labelText="Fecha última intervención"
                                                            type="input"
                                                        >
                                                            <DatePicker
                                                                dateFormat="d/m/Y"
                                                                maxDate={moment().toDate()}
                                                                id="LastMaintenance"
                                                                name="LastMaintenance"
                                                                error={!validation.LastMaintenance && isDirty}
                                                                autoComplete="nope"
                                                                value={OutOfWindowAction.lastMaintenance}
                                                                disabled={OutOfWindowAction.doesntHaveMaintenance}
                                                                onChange={(e) =>
                                                                    OutOfWindowAction.setLastMaintenance(e)
                                                                }
                                                                onBlur={(e) => {
                                                                    OutOfWindowAction.setLastMaintenance(e);
                                                                }}
                                                                locale={region}
                                                            />
                                                        </FormField>
                                                    </Layout.Item>
                                                </Layout>
                                            </Layout.Item>
                                        </>
                                    )}
                                </Layout>
                            </Fieldset.Row>
                        </React.Fragment>
                    </Form>
                </Layout.Item>
            )}
            {stepSelected === "Mantenimientos" && <MantenimientoStandAlone />}
            <Layout.Item>
                <div className="u-text-center">
                    {stepSelected === "FueraVentana" && <Button onClick={() => handleNext()}> Siguiente </Button>}
                </div>
            </Layout.Item>
        </>
    );
};
