import { types } from "../types/types";

import { calculate, setIsCalculating } from "./offerAction";
import { adapterCalculateOffer } from "../adapters/adapterCalculateOffer";
import AdditionalProductsService from "../services/AdditionalProductsService";
import {
    setSelectedAdditionalProductMantenimientoLD,
    setSelectedAdditionalProductPagoProtegido,
    setSelectedAdditionalProductPerdidaTotal,
    setSelectedAdditionalProductRetiradaCarnet,
    setSelectedAdditionalProductSeguroAutomovil,
    setSelectedAdditionalProductsData,
} from "../actions/summaryAction";
import { addNotification, addNotificationError } from "./notificationAction";
import Notification, { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../class/Notification";
import { adapterConvertAdditionalProductToSelectedList } from "../adapters/adapterConvertAdditionalProductToSelectedList";
import { ADDITIONALPRODUCTS_TYPES } from "../class/AdditionalProductsTypes";

export const sendAdditionalProducts = () => {
    return async (dispatch) => {
        // send Offer
        dispatch(sendCalculateOffer());
        dispatch(setShowModalAddProd(false));
        return;
    };
};
export const removelAllAdditionalProductsInHost = () => {
    return async (dispatch, getState) => {
        const { selectedAdditionalProducts } = getState().summary;
        dispatch(
            setSelectedAdditionalProductMantenimientoLD(
                adapterConvertAdditionalProductToSelectedList(
                    selectedAdditionalProducts,
                    [],
                    ADDITIONALPRODUCTS_TYPES.MANTENIMIENTO,
                    true
                ),
                false,
                true
            )
        );
        //Seguro Automovil
        dispatch(
            setSelectedAdditionalProductSeguroAutomovil(
                adapterConvertAdditionalProductToSelectedList(
                    selectedAdditionalProducts,
                    [],
                    ADDITIONALPRODUCTS_TYPES.SEGUROAUTOMOVIL,
                    true
                ),
                false,
                true
            )
        );
        //Perdida Total
        dispatch(
            setSelectedAdditionalProductPerdidaTotal(
                adapterConvertAdditionalProductToSelectedList(
                    selectedAdditionalProducts,
                    [],
                    ADDITIONALPRODUCTS_TYPES.PERDIDATOTAL,
                    true
                ),
                false,
                true
            )
        );
        //Pago Protegido
        dispatch(
            setSelectedAdditionalProductPagoProtegido(
                adapterConvertAdditionalProductToSelectedList(
                    selectedAdditionalProducts,
                    [],
                    ADDITIONALPRODUCTS_TYPES.PAGOPROTEGIDO,
                    true
                ),
                false,
                true
            )
        );
        //Retirada Carnet
        dispatch(
            setSelectedAdditionalProductRetiradaCarnet(
                adapterConvertAdditionalProductToSelectedList(
                    selectedAdditionalProducts,
                    [],
                    ADDITIONALPRODUCTS_TYPES.RETIRADACARNE,
                    true
                ),
                false,
                true
            )
        );
        return;
    };
};
export const sendCalculateOffer = () => {
    return async (dispatch, getState) => {
        // send Offer
        const {
            selectedProduct,
            selectedPlan,
            selectedCampain,
            selectedMeses,
            selectedKms,
            selectedPrecio,
            selectedIsInitAmount,
            selectedServices,
            selectedTires,
            selectedAdditionalProducts,
        } = getState().summary;

        dispatch(
            calculate(
                adapterCalculateOffer(
                    selectedProduct,
                    selectedPlan,
                    selectedCampain,
                    selectedServices,
                    selectedAdditionalProducts,
                    {
                        Months: selectedMeses,
                        Km: selectedKms,
                        Price: selectedPrecio,
                        IsInitAmount: selectedIsInitAmount,
                        Tires: selectedTires ?? null,
                    }
                )
            )
        );
        return;
    };
};
export const getAdditionalProducts = () => {
    return async (dispatch, getState) => {
        const { calculatorType } = getState().global;
        if (calculatorType === 3) {
            dispatch(setIsCalculating(true));
            // Get additional Products and check if have anyone,
            let additionalProductsService = new AdditionalProductsService();
            const { selectedCampain, selectedProduct } = getState().summary;

            const obj = {
                Product: { Key: selectedProduct.Codigo },
                Campain: { Key: selectedCampain.Codigo },
                Months: selectedCampain.MesesPorDefecto || selectedCampain.MesesMin,
                Km: selectedCampain.KilometrosPorDefecto || selectedCampain.KilometrosMin,
                InitAmount: selectedCampain.EntradaInicial || selectedCampain.EntradaInicialMin,
                TargetQuote: selectedCampain.CuotaObjetivo,
            };

            const { data, status, errors } = await additionalProductsService.getAdditionalProducts(obj);
            if (status === 200) {
                dispatch(setAdditionalProductsList(data));
            } else {
                dispatch(addNotificationError(errors));
            }
        }
        dispatch(setIsCalculating(false));
        return;
    };
};
export const getAdditionalProductsData = () => {
    return async (dispatch, getState) => {
        dispatch(setIsCalculating(true));
        const { showModalAddProd } = getState().addicionalProducts;
        const { calculatorType } = getState().global;
        if (calculatorType === 3 && !showModalAddProd) {
            // Get additional Products and check if have anyone,
            let additionalProductsService = new AdditionalProductsService();
            const { selectedCampain, selectedProduct, selectedMeses, selectedKms } = getState().summary;

            const obj = {
                Product: { Key: selectedProduct.Codigo },
                Campain: { Key: selectedCampain.Codigo },
                Months: selectedMeses,
                Km: selectedKms,
                InitAmount: selectedCampain.EntradaInicial || selectedCampain.EntradaInicialMin,
                TargetQuote: selectedCampain.CuotaObjetivo,
            };

            const { data, status, errors } = await additionalProductsService.getAdditionalProductsData(obj);
            if (status === 200) {
                const Productos = {};
                Object.keys(data).forEach((Key) => {
                    if (data[Key] != null) {
                        Productos[Key] = data[Key];
                    }
                });

                dispatch(setSelectedAdditionalProductsData({}));
                dispatch(setAdditionalProducts(Productos));
                dispatch(setOpenTab(Object.keys(Productos)[0] ?? ""));
                dispatch(setShowModalAddProd(true));
            } else {
                dispatch(addNotificationError(errors));
            }
        } else {
            const notification = new Notification.fromObject({
                Description: "No ha sido possible obtener los Productos Adicionales",
                Timeout: 3000,
                Type: TYPE_NOTIFICACION.TOAST,
                Severity: SEVERITY_NOTIFICACION.ERROR,
            });
            dispatch(addNotification(notification));
        }

        dispatch(setIsCalculating(false));
        return;
    };
};
export const getMantenimientoLDStandAlone = (request) => {
    return async (dispatch) => {
        dispatch(setIsCalculating(true));
        let additionalProductsService = new AdditionalProductsService();
        const { data, status, errors } = await additionalProductsService.getMantenimientoLDStandAlone(request);
        if (status === 200) {
            dispatch(setMantenimientoStandAlone(data));
        } else {
            dispatch(setMantenimientoStandAlone(errors));
            if (errors.length > 0 && errors[0].internalValue !== "No existen aportaciones para el paquete actual")
                dispatch(addNotificationError(errors));
        }
        dispatch(setIsCalculating(false));
        return;
    };
};
export const setAdditionalProductsList = (payload) => ({
    type: types.addictionalProductsSetAdditionalProductsList,
    payload: payload,
});
export const setMantenimientoStandAlone = (payload) => ({
    type: types.addictionalProductsMantenimientoStandAlone,
    payload: payload,
});
export const setSeguroAutomovilStandAlone = (payload) => ({
    type: types.addictionalProductsSeguroAutomovilStandAlone,
    payload: payload,
});
export const setAdditionalProducts = (payload) => ({
    type: types.addictionalProductsSetAdditionalProducts,
    payload: payload,
});
export const setOpenTab = (payload) => ({
    type: types.addictionalProductsSetOpenTab,
    payload: payload,
});
export const setShowModalAddProd = (payload) => ({
    type: types.addictionalProductsShowModalAddProd,
    payload,
});
