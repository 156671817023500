export const checkIfIsDNI = (number) => {
    if (number != undefined) {
        const patternDNI = /^\d{8}[A-Z]$/;

        if (patternDNI.test(number.toUpperCase())) {
            // DNI validation algorithm
            const letters = "TRWAGMYFPDXBNJZSQVHLCKE";
            const dni = parseInt(number.substring(0, 8));
            const letter = number.charAt(8).toUpperCase();
            return letter === letters.charAt(dni % 23);
        }
    }
    return false;
};
