import { adapterInternalError } from "../adapters/adapterInternalError";
import Notification, { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../class/Notification";
import NotificationService from "../services/NotificationService";
import { types } from "../types/types";

export const getNotifications = () => {
    return async (dispatch) => {
        const notificationService = new NotificationService();

        const { data, status, error } = await notificationService.getNotificationsByApplication();

        if (status !== 200) {
            dispatch(addNotificationError(error));
            return;
        }

        if (data?.length > 0) {
            data.forEach(({ Description, Title, Severity, Type }) => {
                const notificacion = new Notification.fromObject({
                    Description,
                    Type,
                    Severity,
                    Title,
                });

                dispatch(addNotification(notificacion));
            });
        }
    };
};

export const addNotificationError = (errors) => {
    return (dispatch) => {
        const userError = errors?.length > 0 && errors[0]?.value ? errors[0].value : "Ha ocurrido un error";
        const internalError = adapterInternalError(
            errors?.length > 0 && errors[0]?.internalValue ? errors[0].internalValue : ""
        );

        var notification = new Notification.fromObject({
            Description: <span className="u-text-fs-3">{internalError === "" ? userError : internalError}</span>,
            Type: TYPE_NOTIFICACION.TOAST,
            Severity: SEVERITY_NOTIFICACION.ERROR,
            Timeout: 10000,
        });
        dispatch(addNotification(notification));
    };
};

export const addNotificationSuccess = (msg) => {
    return (dispatch) => {
        var notification = new Notification.fromObject({
            Description: msg,
            Type: TYPE_NOTIFICACION.TOAST,
            Severity: SEVERITY_NOTIFICACION.SUCCESS,
            Timeout: 4000,
        });
        dispatch(addNotification(notification));
    };
};

export const addNotificationModal = (msg, title) => {
    return (dispatch) => {
        var notification = new Notification.fromObject({
            Description: msg,
            Type: TYPE_NOTIFICACION.MODAL,
            Title: title,
        });
        dispatch(addNotification(notification));
    };
};

export const addNotificationAlert = (msg, title, severity) => {
    return (dispatch) => {
        var notification = new Notification.fromObject({
            Title: title,
            Description: msg,
            Type: TYPE_NOTIFICACION.ALERT,
            Severity: severity,
        });
        dispatch(addNotification(notification));
    };
};

export const addNotification = (notification) => {
    return (dispatch) => {
        dispatch({
            type: types.notificationAdd,
            payload: notification,
        });
        if (notification.Timeout) dispatch(setTimeOutForNotifications(notification));
    };
};

const setTimeOutForNotifications = (notification) => {
    return (dispatch) => {
        setTimeout(() => dispatch(deleteNotification(notification)), notification.Timeout);
    };
};

export const deleteNotification = (notificacion) => {
    return {
        type: types.notificationDelete,
        payload: notificacion.Id,
    };
};
