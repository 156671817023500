import React from "react";
import {
  Layout,
  FormField,
  Input,
  Heading,
  Fieldset,
} from "@vwfs-bronson/bronson-react";

export const DirectionResume = ({ clientData }) => {
  return (
    <>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/1">
            <Heading level={6} className="u-text-left u-mt u-mb-none">
              Dirección
            </Heading>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/4" xs="1/1">
            <FormField
              labelForId="StreetType"
              labelText="Tipo vía"
              type="input"
            >
              <Input
                placeholder="Tipo vía"
                defaultValue={clientData.StreetType}
                readOnly
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="2/4" xs="1/1">
            <FormField
              labelForId="StreetName"
              labelText="Nombre vía"
              type="input"
            >
              <Input
                placeholder="Nombre vía"
                defaultValue={clientData.StreetName}
                readOnly
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/4" xs="1/1">
            <FormField labelForId="StreetNumber" labelText="Nº" type="input">
              <Input
                placeholder="Nº"
                defaultValue={clientData.StreetNumber}
                readOnly
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/6" xs="1/1">
            <FormField labelForId="StreetBlock" labelText="Bloque" type="input">
              <Input
                placeholder="Bloque"
                defaultValue={clientData.StreetBlock}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/6" xs="1/1">
            <FormField
              labelForId="StreetStairs"
              labelText="Escalera"
              type="input"
            >
              <Input
                placeholder="Escalera"
                defaultValue={clientData.StreetStairs}
                readOnly
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/6" xs="1/1">
            <FormField labelForId="StreetFloor" labelText="Piso" type="input">
              <Input
                placeholder="Piso"
                defaultValue={clientData.StreetFloor}
                readOnly
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/6" xs="1/1">
            <FormField labelForId="StreetDoor" labelText="Puerta" type="input">
              <Input
                placeholder="Puerta"
                defaultValue={clientData.StreetDoor}
                readOnly
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="2/6" xs="1/1">
            <FormField
              labelForId="StreetComplement"
              labelText="Complemento dirección"
              type="input"
            >
              <Input
                placeholder="Complemento dirección"
                defaultValue={clientData.StreetComplement}
                readOnly
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/3" xs="1/1">
            <FormField
              labelForId="ZipCode"
              labelText="Código Postal"
              type="input"
            >
              <Input
                placeholder="Código Postal"
                defaultValue={clientData.ZipCode}
                readOnly
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/3" xs="1/1">
            <FormField labelForId="Province" labelText="Provincia" type="input">
              <Input
                placeholder="Provincia"
                defaultValue={clientData.Province}
                readOnly
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/3" xs="1/1">
            <FormField
              labelForId="Poblacion"
              labelText="Población"
              type="input"
            >
              <Input
                placeholder="Población"
                defaultValue={clientData.Poblacion}
                readOnly
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
    </>
  );
};
