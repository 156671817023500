import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";
import VehicleService from "../services/VehicleService";

export const getVehicle = () => {
    return async (dispatch) => {
        let vehicleservice = new VehicleService();
        const { data, status, errors } = await vehicleservice.getVehicle();

        if (status === 200) {
            data.DepositAmount = data.DepositAmount ? data.DepositAmount / 100 : 0;
            dispatch({
                type: types.vehicleSetVehicle,
                payload: data,
            });
        } else {
            dispatch(addNotificationError(errors));
        }
    };
};
