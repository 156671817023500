import { postRequest } from "../config/axiosClient.js";

class OfferMantenimientoLongDriveService {
    constructor() {
        this.baseUrl = "OfferMantenimientoLongDriveService";
    }
    async saveMantenimientoStandAloneOffer(body) {
        return await postRequest(`${this.baseUrl}/SaveMantenimientoStandAloneOffer`, body);
    }
}

export default OfferMantenimientoLongDriveService;
