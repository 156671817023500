import Utility from "../services/UtilitiesService";
import { loading } from "../store/reducers/commonHandlers";
import { CLIENT_TYPE_KEY, types } from "../types/types";

export const initialState = {
    client: null,
    shouldIncludeTax: false,
    loading: true,
    error: null,
    clientData: null,
};

const getClient = (state, action) => {
    return Utility.updateObjectReducer(state, {
        client: action.payload,
        shouldIncludeTax: action.payload.key === CLIENT_TYPE_KEY.PHYSICAL,
        loading: false,
    });
};

const setIncludeTax = (state, action) => {
    return Utility.updateObjectReducer(state, {
        shouldIncludeTax: action.payload,
        loading: false,
    });
};

export const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.clientLoading:
            return loading(state, action);
        case types.clientError:
            return {
                type: types.clientError,
                error: action.payload,
            };
        case types.clientGetClient:
            return getClient(state, action);
        case types.clientSetIncludeTax:
            return setIncludeTax(state, action);
        case types.clientSetClientData:
            return {
                ...state,
                clientData: action.payload,
            };
        default:
            return state;
    }
};
