import React from "react";

import { Layout, Heading } from "@vwfs-bronson/bronson-react";
import { FinanceOfferResume } from "./Finance/FinanceOfferResume";
import { RentingOfferResume } from "./Renting/RentingOfferResume";
import { SeguroAutomovilResume } from "./SeguroAutomovil/SeguroAutomovilResume";
import { ClientInfoResume } from "./ClientInfo/ClientInfoResume";
import { NotSelectedResume } from "./NotSelectedResume";
import { SeguroDocs } from "./SeguroDocs/SeguroDocs";
import { ExtraServicesResume } from "./ExtraServices/ExtraServicesResume";

export const Resume = () => {
    return (
        <>
            <Layout.Item>
                <Heading level={2} className="u-text-center">
                    Resumen
                </Heading>
            </Layout.Item>
            <FinanceOfferResume />
            <RentingOfferResume />
            <ExtraServicesResume />
            {/* <MantenimientoResume /> */}
            <SeguroAutomovilResume />
            <ClientInfoResume />
            <SeguroDocs />
            <NotSelectedResume />
        </>
    );
};
