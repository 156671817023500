export const adapterMantenimientoLD = ({ MantenimientoLD }, { Mantenimientos }) => {
    if (!MantenimientoLD || !Mantenimientos) {
        return {};
    }
    const obj = MantenimientoLD[0];
    const mant = Mantenimientos.filter((x) => x.Codigo === obj.ParentCode)[0];

    let resp = {};

    if (mant !== undefined) {
        resp = {
            CodigoPaquete: mant.Codigo,
            NombrePaquete: mant.DenominacionComercial,
            Kms: mant.ServicioInicial.Kilometraje,
            KmsContratados: obj.Key,
            CodigoProducto: mant.ServicioInicial.CodigoServicio,
            KmsMT: mant.ServicioInicial.Kilometraje,
            DuracionMT: mant.ServicioInicial.MesesDuracion,
            Cuota: obj.Servicio.ImporteBrutoTarifa,
            Importe: mant.ServicioInicial.ImporteBrutoCliente,
            CodigoNeumatico: "",
            NumNeumaticos: "",
        };
    }

    return resp;
};
