import React, { useEffect, useState } from "react";
import { useNotification } from "../../../hooks/useNotification";
import { TYPE_NOTIFICACION } from "../../../class/Notification";

import { ContextNotification, Notification } from "@vwfs-bronson/bronson-react";
import { deleteNotification } from "../../../actions/notificationAction";
import { useDispatch } from "react-redux";

export const NotificationAlert = () => {
    const { notifications } = useNotification();
    const dispatch = useDispatch();

    const [notificationsAlert, setNotificationsAlert] = useState([]);

    useEffect(() => {
        setNotificationsAlert(
            notifications.filter((x) => x.Type === TYPE_NOTIFICACION.ALERT).map((x) => ({ ...x, Shown: true }))
        );
    }, [notifications]);

    const handleOnClose = (notification) => {
        dispatch(deleteNotification(notification));
    };

    if (notificationsAlert.length === 0) return null;

    return (
        <ContextNotification>
            {notificationsAlert.length > 0 &&
                notificationsAlert.map((x, index) => (
                    <Notification
                        key={"NotificationAlert_" + index}
                        onCloseClick={() => handleOnClose(x)}
                        status={notificationsAlert[0].Severity}
                        title={notificationsAlert[0].Title}
                        visible
                    >
                        <React.Fragment key=".0">{notificationsAlert[0].Description}</React.Fragment>
                    </Notification>
                ))}
        </ContextNotification>
    );
};
