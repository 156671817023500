import { types } from "../types/types";

export const initialState = {
    vehicle: null,
    loading: false,
    error: null,
};

const vehicleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.vehicleSetVehicle:
            return {
                ...state,
                vehicle: action.payload,
            };
        default:
            return state;
    }
};

export default vehicleReducer;
