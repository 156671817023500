import { types } from "../types/types";

export const initialState = {
    isStarted: false,
    calculatorType: localStorage.getItem("VWFS_simulatorType")
        ? parseInt(localStorage.getItem("VWFS_simulatorType"))
        : 1,
    maxOffersToSend: 1,
    isPricesWithTax: true,
    hasAccessToLongDrive: false,
    returnSalesassistInfo: null,
    downPaymentError: false,
};

export const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.globalSetCalculatorType:
            return {
                ...state,
                calculatorType: action.payload,
            };
        case types.globalSetIsStarted:
            return {
                ...state,
                isStarted: action.payload,
            };
        case types.globalSetMaxOffersToSend:
            return {
                ...state,
                maxOffersToSend: action.payload,
            };
        case types.globalSetisPricesWithTax:
            return {
                ...state,
                isPricesWithTax: action.payload,
            };
        case types.globalSetHasAccessToLongDrive:
            return {
                ...state,
                hasAccessToLongDrive: action.payload,
            };
        case types.globalSetreturnSalesassistInfo:
            return {
                ...state,
                returnSalesassistInfo: action.payload,
            };
        case types.globalSetIsSalesAssist:
            return {
                ...state,
                isSalesAssist: action.payload,
            };
        case types.globalSetDownPaymentError:
            return {
                ...state,
                downPaymentError: action.payload,
            };
        default:
            return state;
    }
};
