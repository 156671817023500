import { types } from "../types/types";

export const initialState = {
    notifications: [],
};

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.notificationDelete:
            return {
                ...state,
                notifications: state.notifications.filter((item) => item.Id !== action.payload),
            };
        case types.notificationAdd:
            return {
                ...state,
                notifications: state.notifications.concat(action.payload),
            };
        default:
            return state;
    }
};
