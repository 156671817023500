export const convertMomentjsDateFormatToFlactPicker = (momentFormat) => {
    const conversionTable = {
        YYYY: "Y",
        YY: "y",
        MMMM: "F",
        MMM: "M",
        MM: "m",
        M: "n",
        DD: "d",
        D: "j",
        HH: "H",
        H: "G",
        hh: "h",
        h: "g",
        mm: "i",
        ss: "S",
        A: "K",
        a: "K",
    };

    Object.keys(conversionTable).forEach((Key) => {
        momentFormat = momentFormat.replace(Key, conversionTable[Key]);
    });
    return momentFormat;
};
