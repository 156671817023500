import React from "react";

export const SVGDesgaste = ({ color = "white", background = "rgb(255, 171, 47)" }) => {
    return (
        <svg className="svg-default" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 69 69">
            <defs></defs>
            <g transform="translate(-918 -85)">
                <circle
                    className="a"
                    cx="34.5"
                    cy="34.5"
                    r="34.5"
                    transform="translate(918 85)"
                    fill={background}
                ></circle>
                <path
                    className="b"
                    d="M153.352,116.817c-7.017-7.744-17.329-8.174-22.914-5.593.143.143.143.43.286.574.859,2.008.859,1.864-1,2.868a17.93,17.93,0,0,0-8.306,8.6c-.43,1-.716,1-1.719.574-.43-.143-1-.43-1.432-.574-3.007,7.027-1.432,17.782,7.3,24.091a21.073,21.073,0,0,0,27.927-3.011A20.793,20.793,0,0,0,153.352,116.817Zm-15.467,27.389A13.77,13.77,0,1,1,151.49,130.44,13.858,13.858,0,0,1,137.885,144.207ZM123.85,116.817a21.14,21.14,0,0,1,5.585-3.728c-.716-1.577-1.289-3.011-2.005-4.589a24.311,24.311,0,0,0-12.03,11.9c1.575.574,3.007,1.291,4.583,1.864A24.939,24.939,0,0,1,123.85,116.817Zm-5.012,3.155c-.143,0-.43-.43-.573-.574a3.135,3.135,0,0,1,.43-.717,22.371,22.371,0,0,1,7.161-7.027c.43-.287.859-.43,1.289.143s.143.86-.286,1.147a22.739,22.739,0,0,0-6.731,6.6A2.308,2.308,0,0,1,118.837,119.972Zm18.9-1.434a12.112,12.112,0,0,0-12.173,11.9,12.032,12.032,0,0,0,24.06.287A11.842,11.842,0,0,0,137.741,118.538Zm-7.734,15.917a1.577,1.577,0,0,1,0-3.155,1.832,1.832,0,0,1,1.719,1.577A1.721,1.721,0,0,1,130.008,134.455Zm2.721-8.747a1.627,1.627,0,0,1-1.575-1.721,1.575,1.575,0,1,1,3.151,0A1.477,1.477,0,0,1,132.729,125.708Zm4.869,14.34a1.546,1.546,0,0,1-1.575-1.721,1.626,1.626,0,0,1,1.719-1.577,1.5,1.5,0,0,1,1.575,1.577A1.844,1.844,0,0,1,137.6,140.048Zm0-6.453a3.308,3.308,0,0,1-3.151-3.3,3.213,3.213,0,0,1,3.294-3.155,3.181,3.181,0,0,1,3.151,3.155C140.749,132.3,139.317,133.738,137.6,133.595Zm4.869-7.887a1.59,1.59,0,0,1-1.575-1.577,1.5,1.5,0,0,1,1.575-1.577,1.577,1.577,0,0,1,0,3.155Zm4.44,7.17a1.59,1.59,0,0,1-1.575,1.577,1.627,1.627,0,0,1-1.575-1.721,1.59,1.59,0,0,1,1.575-1.577A1.832,1.832,0,0,1,146.907,132.878Z"
                    transform="translate(815.367 -10.5)"
                    fill={color}
                ></path>
            </g>
        </svg>
    );
};
