import React from "react";

import { DescriptionList } from "@vwfs-bronson/bronson-react";
import { financialFormat } from "../../../../helpers/financialFormat";

export const ModalOfferDetailsAdditionalProducts = ({ offer }) => {
    if (!offer.AdditionalProducts || Object.values(offer.AdditionalProducts?.Servicios).length === 0) return <></>;

    return (
        <DescriptionList grid>
            <DescriptionList.Group termText="Productos adicionales incluidos">
                {offer.AdditionalProducts?.Servicios.map((additionalService) => (
                    <DescriptionList.Detail key={`Modal_AdditionalProduct_${additionalService.CodigoServicio}`}>
                        {additionalService.DescripcionServicio} -{" "}
                        {financialFormat(additionalService.ImporteServicio, 2)} €
                    </DescriptionList.Detail>
                ))}
            </DescriptionList.Group>
        </DescriptionList>
    );
};
