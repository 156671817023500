import { types } from "../types/types";

export const initialState = {
    Countries: [],
    StreetTypes: [],
};

export const locationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.locationGetAllCountries:
            return {
                ...state,
                Countries: action.payload,
            };
        case types.locationGetAllStreetTypes:
            return {
                ...state,
                StreetTypes: action.payload,
            };
        default:
            return state;
    }
};
