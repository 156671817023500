import { postRequest } from "../config/axiosClient.js";

class InsuranceService {
    constructor() {
        this.baseUrl = "InsuranceService";
    }
    async getAutoInsuranceStandAloneLDTarificacion(body) {
        return await postRequest(`${this.baseUrl}/GetAutoInsuranceStandAloneLDTarificacion`, body);
    }
}

export default InsuranceService;

