
import { adapterClientDateFormat } from "../adapters/adapterClientDateFormat";
import InsuranceService from "../services/InsuranceService";
import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";
import { setIsCalculating } from "./offerAction";

export const GetTarificacionZurichLongDrive = () => {
    return async (dispatch, getState) => {
        dispatch(setIsCalculating(true));
        const { selectedSeguroAutoStandAlone } = getState().summary;
        
        
        var request = [adapterClientDateFormat(selectedSeguroAutoStandAlone.Tomador), adapterClientDateFormat(selectedSeguroAutoStandAlone.ConductorHabitual)];
        
        if(selectedSeguroAutoStandAlone.CondutorOcasional?.NIF !== ""){
            request.push(adapterClientDateFormat(selectedSeguroAutoStandAlone.CondutorOcasional));
        }
        
        var insuranceService = new InsuranceService();
        const { data, status, errors } = await insuranceService.getAutoInsuranceStandAloneLDTarificacion(request);

        dispatch(setIsCalculating(false));
        if(status === 200 ){
            dispatch(setTarificacionZurich(data));
            return true;
        } else {
            dispatch(setTarificacionZurich(errors));
            if(errors[0].internalValue !== "No existen aportaciones para el paquete actual")
                dispatch(addNotificationError(errors));
            return false;
        }
    };
};

export const setTarificacionZurich = (payload) => ({
    type: types.insuranceSetTarifasZurich,
    payload,
});

export const setSeguroStandAlone = (Tomador, ConductorHabitual, ConductorOcasional, seguro) => {
    const Condhabitual = ConductorHabitual ? Object.assign({}, Tomador) : ConductorHabitual;
    Condhabitual.ClaveRol = "COH";
    return {
        Tomador: Tomador,
        ConductorHabitual: Condhabitual,
        CondutorOcasional: ConductorOcasional,
        SeguroContratado: seguro,
    };
};