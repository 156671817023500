import AppService from "./AppService.js";
import { postRequest } from "../config/axiosClient.js";

class AuthenticationService {
    constructor() {
        this.baseUrl = "authenticationservice";
    }

    async refreshtoken(tokenJWT) {
        return AppService.post(`${this.baseUrl}/refreshtoken`, tokenJWT, null, "core").then((response) => {
            return response;
        });
    }

    async resetExpirationTime() {
        return AppService.post(`${this.baseUrl}/resetExpirationTime`, {}, null, "core").then((response) => {
            return response;
        });
    }

    async getPermisosAccionApplicacion(obj) {
        return await postRequest(`${this.baseUrl}/readpermisosaccionapplication`, obj);
    }
    async createPermisosAccionApplicacion(obj) {
        return await postRequest(`${this.baseUrl}/createpermisosaccionapplication`, obj);
    }
    async updatePermisosAccionApplicacion(obj) {
        return await postRequest(`${this.baseUrl}/updatepermisosaccionapplication`, obj);
    }

    async getTiposAccion(obj) {
        return await postRequest(`${this.baseUrl}/readtiposaccion`, obj);
    }
    async createTiposAccion(obj) {
        return await postRequest(`${this.baseUrl}/createtiposaccion`, obj);
    }
    async getApplicacionesExternas(obj) {
        return await postRequest(`${this.baseUrl}/readapplicacionesexternas`, obj);
    }
    async createApplicacionesExternas(obj) {
        return await postRequest(`${this.baseUrl}/createapplicacionesexternas`, obj);
    }
    async getPermisosTemporales(obj) {
        return await postRequest(`${this.baseUrl}/readpermisostemporales`, obj);
    }
}

export default AuthenticationService;
