import * as authTypes from "./actionTypes/authActionTypes";

export const handleError = (object, type) => {
    return (dispatch, getState) => {
        let isSessionOut = getState().auth.sessionOut;
        var error = "";
        var code = "500";
        var internalValue = "";
        var updateReducer = true;
        if (object.error) {
            code = object.statusCode;
            error = object.error[0].value;
            internalValue = object.error[0].internalValue;
        } else if (object.body && object.body.error && object.body.error.length > 0) {
            code = object.body.error[0].code.toString();
            error = object.body.error[0].value;
        } else {
            error = object.message;
            if (object.code === 409)
                // es una operacion cancelada
                updateReducer = false;
        }
        if (!isSessionOut && code === "403") {
            dispatch({
                type: authTypes.AUTH_UNAUTHORIZE,
            });
        }
        if (updateReducer)
            dispatch({
                type,
                payload: { error, code, internalValue },
            });
    };
};
