import { Layout, DescriptionList, InlineGroup } from "@vwfs-bronson/bronson-react";
import { ModalOfferDetailsAdditionalProducts } from "./ModalOfferDetailsAdditionalProducts";
import { financialFormat } from "../../../../helpers/financialFormat";

export const FinanceInfo = ({ offer }) => {
    if (!offer) return null;

    const { TablaCuotas } = offer.MonthlyFeePerYear ?? {};

    return (
        <Layout className="row u-mb">
            <Layout.Item default="1/1">
                <InlineGroup bottom>
                    <DescriptionList grid>
                        <DescriptionList.Group termText="Campaña">
                            <b>{offer.Campain?.Value}</b>
                        </DescriptionList.Group>
                    </DescriptionList>
                    <DescriptionList grid>
                        <DescriptionList.Group termText="Aplazamiento">
                            <b>{offer.Months} Meses</b>
                        </DescriptionList.Group>
                    </DescriptionList>
                    {offer.Km !== "" && (
                        <DescriptionList grid>
                            <DescriptionList.Group termText="kilometraje Anual">
                                <b>{offer.Km} Km</b>
                            </DescriptionList.Group>
                        </DescriptionList>
                    )}
                    <DescriptionList grid>
                        <DescriptionList.Group termText="Entrada">
                            <b>{parseInt(offer.InitAmount) > 0 ? financialFormat(offer.InitAmount) + " €" : "No"}</b>
                        </DescriptionList.Group>
                    </DescriptionList>
                    <DescriptionList grid>
                        <DescriptionList.Group termText="TIN">
                            <b>{financialFormat(offer.PorcentajeTIN)} %</b>
                        </DescriptionList.Group>
                    </DescriptionList>
                    <DescriptionList grid>
                        <DescriptionList.Group termText="TAE">
                            <b>{financialFormat(offer.PorcentajeTAE)} %</b>
                        </DescriptionList.Group>
                    </DescriptionList>
                    <DescriptionList grid>
                        <DescriptionList.Group termText="Comisión de Apertura Financiada">
                            <b>{financialFormat(offer.ComisionApertura)} €</b>
                        </DescriptionList.Group>
                    </DescriptionList>
                    <DescriptionList grid>
                        <DescriptionList.Group termText="Importe del crédito">
                            <b>{financialFormat(offer.ImporteTotalFinanciado)} €</b>
                        </DescriptionList.Group>
                    </DescriptionList>
                    {(offer.Product.Key === "AC" || offer.Product.Key === "AF") &&
                        offer.ImportePrecioEstimadoVenta !== "0" && (
                            <DescriptionList grid>
                                <DescriptionList.Group termText="Valor Mínimo Garantizado">
                                    <b>{financialFormat(offer.ImportePrecioEstimadoVenta)} €</b>
                                </DescriptionList.Group>
                            </DescriptionList>
                        )}
                    <ModalOfferDetailsAdditionalProducts offer={offer}></ModalOfferDetailsAdditionalProducts>
                    {offer.Product.Key === "AF" ? (
                        <Layout>
                            <Layout.Item>
                                {TablaCuotas.map((quote, index) => (
                                    <div key={index}>
                                        {`${quote.NumCuotas} cuotas mensuales de `}
                                        <b>{quote.ImpNominal} €</b>
                                    </div>
                                ))}
                            </Layout.Item>
                        </Layout>
                    ) : (
                        <DescriptionList grid>
                            <DescriptionList.Group termText="Cuota mensual">
                                <b>{financialFormat(offer.FeeAmount)} €</b>
                            </DescriptionList.Group>
                        </DescriptionList>
                    )}
                    {offer.Product.Key === "AF" && (
                        <DescriptionList grid>
                            <DescriptionList.Group termText="Periodo Mínimo de Permanencia">
                                <b>{offer.MonthlyFeePerYear.PeriodoMinimoPermanencia} meses</b>
                            </DescriptionList.Group>
                        </DescriptionList>
                    )}
                </InlineGroup>
            </Layout.Item>
        </Layout>
    );
};
