export const adapterCalculateOffer = (
    selectedProduct,
    selectedPlan,
    selectedCampain,
    selectedServices,
    selectedAdditionalProducts,
    { Months, Km, Price, IsInitAmount, Tires }
) => {
    const CodigoProveedor =
        selectedAdditionalProducts &&
        selectedAdditionalProducts.SegurosAutomovilLD &&
        selectedAdditionalProducts.SegurosAutomovilLD.length > 0
            ? selectedAdditionalProducts.SegurosAutomovilLD[0].CodigoProveedor
            : null;

    const obj = {
        Product: {
            Key: selectedProduct.Codigo,
        },
        Campain: { Key: selectedCampain.Codigo },
        Months,
        Km,
        InitAmount: IsInitAmount === "0" ? Price : null,
        TargetQuote: IsInitAmount === "1" ? Price : null,
        Services: adapterServices(selectedServices),
        Tires,
        CompanyCodeSABundle: CodigoProveedor,
        Plan: selectedPlan
            ? {
                  IsPlan: true,
                  ImporteSubvencion: selectedPlan.ImporteSubvencion,
                  IndicadorPlan: selectedPlan.Code,
                  Name: selectedPlan.Name,
              }
            : { IsPlan: false },
    };

    return obj;
};

const adapterServices = (services) => {
    if (!services) return [];

    if (services.length === 0) return [];

    return services.map((x) => ({
        Code: x.CodigoNorma,
    }));
};
