import React from "react";

export const SVGMantenimiento = ({ color = "white", background = "rgb(0, 104, 169)" }) => {
    return (
        <svg className="svg-default" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 69 69">
            <defs></defs>
            <g transform="translate(-477 -85)">
                <circle
                    className="a"
                    cx="34.5"
                    cy="34.5"
                    r="34.5"
                    fill={background}
                    transform="translate(477 85)"
                ></circle>
                <path
                    className="b"
                    fill={color}
                    d="M68.34,132.484a37.891,37.891,0,0,1,8.9-2.185c.584-.146,1.314-.146,1.9-.291-.584-1.457-1.168-2.913-1.752-4.224,2.919-1.6,4.379-4.37,5.547-7.429-2.481,1.165-4.817,2.185-7.3,3.2a12.587,12.587,0,0,0-1.022-2.476,3.681,3.681,0,0,1-.292-2.476c0-.146.146-.437.292-.437.584-.291,1.168-.583,1.9-.874,1.314-.583,2.481-1.165,3.941-1.748a11.2,11.2,0,0,0-3.357-1.457,13.84,13.84,0,0,0-6.422.583,3.023,3.023,0,0,0-2.189,2.185c-.146.728-.438,1.311-.584,2.039a40.942,40.942,0,0,1-2.773,8.448,11.742,11.742,0,0,1-2.627,3.787c-5.109,4.37-10.363,8.594-15.618,12.964a4.277,4.277,0,0,0-1.46,4.079,3.9,3.9,0,0,0,2.919,3.35,4.505,4.505,0,0,0,4.379-1.311c4.525-4.661,8.9-9.468,13.283-14.275A7.843,7.843,0,0,1,68.34,132.484ZM49.656,147.779a2.331,2.331,0,1,1,2.335-2.331A2.3,2.3,0,0,1,49.656,147.779Zm32.988-3.5c-3.5-3.642-7.152-7.283-10.655-10.925a.537.537,0,0,0-.438-.146,25.208,25.208,0,0,0-3.5,1.457,22.308,22.308,0,0,0-2.627,2.622l.146.146c3.941,4.079,7.882,8.011,11.823,12.09a1.549,1.549,0,0,0,1.9.437,5.925,5.925,0,0,0,3.8-4.079A1.823,1.823,0,0,0,82.644,144.283Zm-32.7-23.889a7.127,7.127,0,0,1,1.9.874,4.436,4.436,0,0,1,1.022.728c2.335,2.331,4.525,4.661,6.86,6.846.146.146.292.146.438.437.584-.437,1.168-1.02,1.752-1.457a6.339,6.339,0,0,0,.876-1.02.143.143,0,0,0-.146-.146c-1.752-1.748-3.357-3.642-5.255-5.244a12.3,12.3,0,0,1-3.649-5.535,2.558,2.558,0,0,0-.584-.728c-1.606-1.02-3.211-2.185-4.817-3.2a.452.452,0,0,0-.292-.146l-2.627,2.622a50.2,50.2,0,0,1,2.919,4.661A2.935,2.935,0,0,0,49.948,120.394Z"
                    transform="translate(447.65 -11.8)"
                ></path>
            </g>
        </svg>
    );
};
