import QuotationService from "../services/QuotationService";
import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";
import { setSelectedServices } from "./summaryAction";

export const getServices = (obj) => {
    return async (dispatch) => {
        var quotationService = new QuotationService();

        dispatch(setLoadingServices(true));
        dispatch(setServices(null));
        dispatch(setSelectedServices([]));

        var { data, status, errors } = await quotationService.getServices(obj);

        dispatch(setLoadingServices(false));

        if (status === 200) {
            dispatch(setServices(data));
        } else {
            dispatch(addNotificationError(errors));
        }
    };
};

export const setServices = (payload) => ({
    type: types.serviceSetServices,
    payload,
});

export const setLoadingServices = (payload) => ({
    type: types.serviceSetLoading,
    payload,
});
