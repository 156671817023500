export const hasOffersPriceChange = (offers) => {
    let hasChange = false;
    offers.forEach(({ Simulation, Offer }) => {
        if (Simulation.ImporteBase !== Offer.ImporteBase) {
            hasChange = true;
            return;
        }
        if (Simulation.ImporteFianza !== Offer.ImporteFianza) {
            hasChange = true;
            return;
        }
        if (Simulation.ImporteKMDefecto !== Offer.ImporteKMDefecto) {
            hasChange = true;
            return;
        }
        if (Simulation.ImporteKMExceso !== Offer.ImporteKMExceso) {
            hasChange = true;
            return;
        }
        if (Simulation.ImporteKMExcesoAdicional !== Offer.ImporteKMExcesoAdicional) {
            hasChange = true;
            return;
        }
        if (Simulation.ImportePrecioEstimadoVenta !== Offer.ImportePrecioEstimadoVenta) {
            hasChange = true;
            return;
        }
        if (Simulation.ImporteImpuestos !== Offer.ImporteImpuestos) {
            hasChange = true;
            return;
        }
    });

    return hasChange;
};
