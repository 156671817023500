import React, { useEffect } from "react";
import {
  Layout,
  FormField,
  Input,
  Heading,
  Fieldset,
  Select,
} from "@vwfs-bronson/bronson-react";
import { useDispatch } from "react-redux";
import { getProvinceAndMunicipalityByZipCode } from "../../../../actions/locationAction";
import { useLocation } from "../../../../hooks/useLocation";
import { validateZipCode } from "../../../../validators/validateZipCode";
import { validateEmptyField } from "../../../../validators/validateEmptyField";

export const Direction = ({
  formValuesClient,
  setHandleOnChangeClient,
  setValues,
}) => {
  const dispatch = useDispatch();

  const { StreetTypes } = useLocation();

  useEffect(async () => {
    if (validateZipCode(formValuesClient.ZipCode)) {
      const info = await getProvinceAndMunicipalityByZipCode(
        formValuesClient.ZipCode,
        dispatch
      );
      if (info !== null && info !== undefined) {
        //Update province and municipe fields
        setValues({
          ...formValuesClient,
          IdProvince: info.ProvinceCode,
          Province: info.ProvinceDescription,
          IdPoblacion: info.MunicipalityCode,
          Poblacion: info.MunicipalityDescription,
        });
      }
    }
  }, [formValuesClient.ZipCode]);

  return (
    <>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/1">
            <Heading level={5} className="u-text-left u-mt u-mb-none">
              Dirección
            </Heading>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/4" xs="1/1">
            <FormField
              labelForId="StreetType"
              labelText="Tipo vía"
              type="input"
              noFloatingLabel
            >
              <Select
                id="StreetType"
                name="StreetType"
                value={formValuesClient.StreetType}
                onChange={setHandleOnChangeClient}
              >
                <Select.Item disabled value="">
                  Seleccionar tipo de vía
                </Select.Item>
                {StreetTypes?.map((type) => (
                  <Select.Item
                    key={type.StreetTypeCode}
                    value={type.StreetTypeCode}
                  >
                    {type.StreetTypeName}
                  </Select.Item>
                ))}
              </Select>
            </FormField>
          </Layout.Item>
          <Layout.Item default="2/4" xs="1/1">
            <FormField
              labelForId="StreetName"
              labelText="Nombre vía"
              type="input"
            >
              <Input
                id="StreetName"
                name="StreetName"
                placeholder="Nombre vía"
                autoComplete="nope"
                value={formValuesClient.StreetName}
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/4" xs="1/1">
            <FormField labelForId="StreetNumber" labelText="Nº" type="input">
              <Input
                id="StreetNumber"
                name="StreetNumber"
                placeholder="Nº"
                autoComplete="nope"
                value={formValuesClient.StreetNumber}
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/6" xs="1/1">
            <FormField labelForId="StreetBlock" labelText="Bloque" type="input">
              <Input
                id="StreetBlock"
                name="StreetBlock"
                placeholder="Bloque"
                autoComplete="nope"
                value={formValuesClient.StreetBlock}
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/6" xs="1/1">
            <FormField
              labelForId="StreetStairs"
              labelText="Escalera"
              type="input"
            >
              <Input
                id="StreetStairs"
                name="StreetStairs"
                placeholder="Escalera"
                autoComplete="nope"
                value={formValuesClient.StreetStairs}
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/6" xs="1/1">
            <FormField labelForId="StreetFloor" labelText="Piso" type="input">
              <Input
                id="StreetFloor"
                name="StreetFloor"
                placeholder="Piso"
                autoComplete="nope"
                value={formValuesClient.StreetFloor}
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/6" xs="1/1">
            <FormField labelForId="StreetDoor" labelText="Puerta" type="input">
              <Input
                id="StreetDoor"
                name="StreetDoor"
                placeholder="Puerta"
                autoComplete="nope"
                value={formValuesClient.StreetDoor}
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="2/6" xs="1/1">
            <FormField
              labelForId="StreetComplement"
              labelText="Complemento direccción"
              type="input"
            >
              <Input
                id="StreetComplement"
                name="StreetComplement"
                placeholder="Complemento dirección"
                autoComplete="nope"
                value={formValuesClient.StreetComplement}
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/3" xs="1/1">
            <FormField
              labelForId="ZipCode"
              labelText="Código Postal"
              type="input"
            >
              <Input
                id="ZipCode"
                name="ZipCode"
                placeholder="Código Postal"
                autoComplete="nope"
                value={formValuesClient.ZipCode}
                error={
                  !validateZipCode(formValuesClient.ZipCode) &&
                  validateEmptyField(formValuesClient.ZipCode)
                }
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/3" xs="1/1">
            <FormField labelForId="Province" labelText="Provincia" type="input">
              <Input
                id="Province"
                name="Province"
                placeholder="Provincia"
                autoComplete="nope"
                value={formValuesClient.Province}
                onChange={setHandleOnChangeClient}
                readOnly
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/3" xs="1/1">
            <FormField
              labelForId="Poblacion"
              labelText="Población"
              type="input"
            >
              <Input
                id="Poblacion"
                name="Poblacion"
                placeholder="Población"
                autoComplete="nope"
                value={formValuesClient.Poblacion}
                onChange={setHandleOnChangeClient}
                readOnly
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
    </>
  );
};
