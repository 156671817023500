import QuotationService from "../services/QuotationService";
import { types } from "../types/types";
import { setSelectedCampain } from "./summaryAction";
import { addNotificationError } from "./notificationAction";
import { setServices } from "./serviceAction";
import { setCampains } from "./campainAction";
import { setAdditionalProductsList } from "./addictionalProductsAction";

export const getProducts = () => {
    return async (dispatch) => {
        dispatch(setSelectedCampain(null));
        dispatch(setProductLoading(true));

        let quotationservice = new QuotationService();
        const { data, status, errors } = await quotationservice.getProducts();

        dispatch(setProductLoading(false));

        if (status === 200) {
            dispatch(setProduct(data.TablaProductos ?? []));
        } else {
            dispatch(addNotificationError(errors));
        }
    };
};

export const setProduct = (payload) => ({
    type: types.productSetProducts,
    payload,
});
export const setProductLoading = (payload) => ({
    type: types.productSetLoading,
    payload,
});

export const resetProduct = () => {
    return async (dispatch) => {
        dispatch(setProduct([]));
        dispatch(setServices(null));
        dispatch(setCampains([]));
        dispatch(setAdditionalProductsList(null));
    };
};
