import { postRequest } from "../config/axiosClient.js";

class LocationService {
    constructor() {
        this.baseUrl = "LocationService";
    }
    async getAllCountries() {
        return await postRequest(`${this.baseUrl}/GetAllCountries`, {});
    }
    async getAllStreetsTypes() {
        return await postRequest(`${this.baseUrl}/getallstreettypes`, {});
    }
    async getProvinceAndMunicipalityByZipCode(body) {
        return await postRequest(`${this.baseUrl}/GetProvinceAndMunicipalityByZipCode`, body);
    }
}

export default LocationService;
