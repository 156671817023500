import { types } from "../types/types";

export const initialState = {
    tarifasZurich: null
};

const insuranceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.insuranceSetTarifasZurich:
            return {
                ...state,
                tarifasZurich: action.payload,
            };
        default:
            return state;
    }
};

export default insuranceReducer;
