export const adapterApiGatewayProxyResponseToObject = (resp) => {
    const { data } = resp;
    const statusCode = data.statusCode;
    const bodyResp = JSON.parse(data.body);
    const response = {
        ...resp,
        status: statusCode,
        data: bodyResp?.Result ? bodyResp.Result : null,
        errors: bodyResp?.Error,
    };
    return response;
};
