import React, { useContext } from "react";
import { FormSection, Layout, ButtonContainer, Button, Checkbox } from "@vwfs-bronson/bronson-react";
import { WizardConext } from "../../../../contexts/WizardContext";
import { useSummary } from "../../../../hooks/useSummary";

export const SeguroDocs = () => {
    const { download, setdownload, isDirty, setIsSeguroDocsMounted } = useContext(WizardConext);
    const { selectedSeguroAutoStandAlone, selectedResumeOffers } = useSummary();

    const offersSeguros =
        selectedResumeOffers?.filter(
            (x) =>
                x.AdditionalProducts?.Servicios?.filter(
                    (v) => v.CodigoServicio === "SE" || v.CodigoServicio === "SR" || v.CodigoServicio === "SA"
                ).length > 0
        ).length > 0;

    if (!offersSeguros && !selectedSeguroAutoStandAlone?.SeguroContratado) {
        return null;
    } else {
        setIsSeguroDocsMounted(true);
    }

    const handleSetDownload = (e) => {
        setdownload(e);
    };
    const GetDocument = (lang) => {
        const SA = selectedResumeOffers?.map(
            (x) => x.AdditionalProducts?.Servicios?.filter((v) => v.CodigoServicio === "SA").length > 0
        );
        console.log("selectedResumeOffers", JSON.stringify(selectedResumeOffers));
        console.log("SA", JSON.stringify(SA));
        onButtonClick(
            `./assets/Seguros/${lang === "es" ? "Castellano" : "Catalan"}/Nota_Informativa.pdf`,
            "Nota_Informativa.pdf"
        );
        if ((SA && SA.length > 0) || (selectedSeguroAutoStandAlone && selectedSeguroAutoStandAlone.SeguroContratado)) {
            let companyCode = selectedResumeOffers[0].CompanyCodeSABundle;

            if (
                (selectedSeguroAutoStandAlone && selectedSeguroAutoStandAlone.SeguroContratado) ||
                companyCode === "0070"
            )
                onButtonClick(
                    `./assets/Seguros/${lang === "es" ? "Castellano" : "Catalan"}/Seguros_Zurich.pdf`,
                    "Seguros_Zurich.pdf"
                );
            if (companyCode === "0038")
                onButtonClick(
                    `./assets/Seguros/${lang === "es" ? "Castellano" : "Catalan"}/Seguros_Mapfre.pdf`,
                    "Seguros_Mapfre.pdf"
                );
            if (companyCode === "0003")
                onButtonClick(
                    `./assets/Seguros/${lang === "es" ? "Castellano" : "Catalan"}/Seguros_Mutua.pdf`,
                    "Seguros_Mutua.pdf"
                );
        }

        const SE = selectedResumeOffers?.map((x) => {
            return x.AdditionalProducts?.Servicios?.filter((v) => v.CodigoServicio === "SE");
        });
        if (SE && SE.length > 0) {
            onButtonClick(
                `./assets/Seguros/${lang === "es" ? "Castellano" : "Catalan"}/Seguros_PPI.pdf`,
                "Seguros_PPI.pdf"
            );
        }

        const SR = selectedResumeOffers?.map(
            (x) => x.AdditionalProducts?.Servicios?.filter((v) => v.CodigoServicio === "SR").length > 0
        );
        if (SR && SR.length > 0) {
            onButtonClick(
                `./assets/Seguros/${lang === "es" ? "Castellano" : "Catalan"}/Seguro_Retirada_Carnet.pdf`,
                "Seguro_Retirada_Carnet.pdf"
            );
        }

        setdownload(true);
    };
    const onButtonClick = (pdfUrl, fileName) => {
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = fileName; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Layout.Item className="u-mt-small">
            <FormSection defaultOpen={true} subtitle={""} className="u-mb-small" title={"Documentos Seguros"}>
                <ButtonContainer center>
                    <Button onClick={() => GetDocument("es")}>Castellano</Button>
                    <Button onClick={() => GetDocument("ct")}>Catalán</Button>
                </ButtonContainer>
                <Checkbox
                    className="u-mt-small"
                    name="checkbox-demo"
                    value={download}
                    checked={download}
                    onChange={(e) => handleSetDownload(e.target.checked ? true : false)}
                    error={!download && isDirty}
                >
                    Entrega Nota Previa Informativa del Mediador
                </Checkbox>
            </FormSection>
        </Layout.Item>
    );
};
