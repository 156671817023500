import { useState } from "react";

export const useForm = (initialState = {}) => {
    const [values, setValues] = useState(initialState);

    const handleOnChange = ({ target }) => {
        if (target.name.indexOf(".") !== -1) {
            var newValues = { ...values };
            Objectifier.set(target.name, target.value, { ...values });
            setValues({ ...newValues });
        } else setValues({ ...values, [target.name]: target.value });
    };

    const reset = () => {
        setValues(initialState);
    };

    return [values, handleOnChange, reset, setValues];
};

var Objectifier = (function () {
    var objectifier = function (splits, create, context) {
        var result = context || window;
        for (var i = 0, s; result && (s = splits[i]); i++) {
            result = s in result ? result[s] : create ? (result[s] = {}) : undefined;
        }
        return result;
    };

    return {
        set: function (name, value, context) {
            var splits = name.split("."),
                s = splits.pop(),
                result = objectifier(splits, true, context);
            return result && s ? (result[s] = value) : undefined;
        },
        get: function (name, create, context) {
            return objectifier(name.split("."), create, context);
        },
        exists: function (name, context) {
            return this.get(name, false, context) !== undefined;
        },
    };
})();
