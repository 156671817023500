import { postRequest } from "../config/axiosClient.js";
import AppService from "./AppService.js";

class QuotationService {
    constructor() {
        this.baseUrl = "quotationservice";
    }

    async getQuote(body) {
        return await postRequest(`${this.baseUrl}/getquote`, body);
    }

    async getProducts() {
        return await postRequest(`${this.baseUrl}/getproducts`, {});
    }

    async updateProducts(body) {
        return AppService.post(`${this.baseUrl}/updateproduct`, body, null, "core").then((response) => {
            return response;
        });
    }

    async getCampains(body) {
        return await postRequest(`${this.baseUrl}/getcampains`, body);
    }

    async getServices(body) {
        return await postRequest(`${this.baseUrl}/getservices`, body);
    }

    async createQuoteOffer(body) {
        return await postRequest(`${this.baseUrl}/createquoteoffer`, body);
    }

    async recalculateQuoteOffers(body) {
        return AppService.post(`${this.baseUrl}/getrecalculatedoffers`, body, null, "core").then((response) => {
            return response;
        });
    }

    async createSimulationOffer(body) {
        return AppService.post(`${this.baseUrl}/createsimulationoffer`, body, null, "core").then((response) => {
            return response;
        });
    }
}

export default QuotationService;
