import { FAMILY_PRODUCT, types } from "../types/types";

export const initialState = {
    selectedToken: null,
    selectedProductFamily:
        localStorage.getItem("VWFS_simulatorType") && localStorage.getItem("VWFS_simulatorType") === 3
            ? FAMILY_PRODUCT.FINANCING
            : FAMILY_PRODUCT.RENTING,
    selectedPlan: null,
    selectedProduct: null,
    selectedCampain: null,
    selectedOffers: [],
    selectedMeses: null,
    selectedKms: null,
    selectedPrecio: null,
    selectedIsInitAmount: null,
    selectedServices: null,
    selectedTires: null,
    selectedAdditionalProducts: {},
    selectedResumeOffers: [],
    selectedMantenimentoStandAlone: null,
    selectedSeguroAutoStandAlone: null,
    selectedClientStandAlone: null,
};

export const summaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.summarySetSelectedProductFamily:
            return {
                ...state,
                selectedProductFamily: action.payload,
            };
        case types.summarySetSelectedToken:
            return {
                ...state,
                selectedToken: action.payload,
            };
        case types.summarySetSelectedPlan:
            return {
                ...state,
                selectedPlan: action.payload,
            };
        case types.summarySetSelectedProduct:
            return {
                ...state,
                selectedProduct: action.payload,
                selectedCampain: null,
            };
        case types.summarySetSelectedCampain:
            return {
                ...state,
                selectedCampain: action.payload,
            };
        case types.summarySetSelectedMeses:
            return {
                ...state,
                selectedMeses: action.payload,
            };
        case types.summarySetSelectedKms:
            return {
                ...state,
                selectedKms: action.payload,
            };
        case types.summarySetSelectedPrecio:
            return {
                ...state,
                selectedPrecio: action.payload,
            };
        case types.summarySetSelectedIsInitAmount:
            return {
                ...state,
                selectedIsInitAmount: action.payload,
            };
        case types.summarySetSelectedTires:
            return {
                ...state,
                selectedTires: action.payload,
            };
        case types.summarySetSelectedOffers:
            return {
                ...state,
                selectedOffers: [...action.payload],
            };
        case types.summarySetSelectedServices:
            return {
                ...state,
                selectedServices: [...action.payload],
            };
        case types.summarySetSelectedAdditionalProductsData:
            return {
                ...state,
                selectedAdditionalProducts: { ...action.payload },
            };
        case types.summarySetSelectedResumeOffers:
            return {
                ...state,
                selectedResumeOffers: action.payload,
            };
        case types.summarySetSelectedMantenimentoStandAlone:
            return {
                ...state,
                selectedMantenimentoStandAlone: action.payload,
            };
        case types.summarySetSelectedSeguroAutoStandAlone:
            return {
                ...state,
                selectedSeguroAutoStandAlone: action.payload,
            };
        case types.summarySetSelectedClientStandAlone:
            return {
                ...state,
                selectedClientStandAlone: action.payload,
            };
        case types.summaryResetData:
            return { ...initialState };
        default:
            return state;
    }
};
