import React, { useContext } from "react";

import { Layout, Wizard, Card } from "@vwfs-bronson/bronson-react";
import { WizardConext } from "../../contexts/WizardContext";
import { MantenimientoStandAlone } from "./Mantenimiento/MantenimientoStandAlone";
import { SeguroAutomovilStandAlone } from "./SeguroAutomovil/SeguroAutomovilStandAlone";
import { Clientinfo } from "./ClientInfo/Clientinfo";
import { Resume } from "./Resume/Resume";
import { useState } from "react";
import { useEffect } from "react";
import { CalculatorTypeEnum, StepsNameEnum } from "../../types/enums";
import { useGlobals } from "../../hooks/useGlobals";
import { MantenimientoStandAloneVO } from "./Mantenimiento/MantenimientoStandAloneVO/MantenimientoStandAloneVO";
import { useVehicle } from "../../hooks/useVehicle";
import { setIsCalculating } from "../../actions/offerAction";
import { useDispatch } from "react-redux";

export const WizardResumen = () => {
    const { steps, stepSelected } = useContext(WizardConext);
    const { calculatorType } = useGlobals();
    const { vehicle } = useVehicle();
    const [activeStep, setActiveStep] = useState(null);
    const dispatch = useDispatch();

    const showSimulator =
        calculatorType === CalculatorTypeEnum.RENTING || calculatorType === CalculatorTypeEnum.FINANCE;

    useEffect(() => {
        switch (stepSelected?.name) {
            case StepsNameEnum.MANTENIMIENTO:
                if (!vehicle) {
                    dispatch(setIsCalculating(true));
                    return;
                }
                if (vehicle && vehicle?.Type?.Key === "VO")
                    setActiveStep(
                        <Card element="article">
                            <Layout>
                                <MantenimientoStandAloneVO></MantenimientoStandAloneVO>
                            </Layout>
                        </Card>
                    );
                else setActiveStep(<MantenimientoStandAlone></MantenimientoStandAlone>);
                break;
            case StepsNameEnum.SEGUROAUTOMOVIL:
                setActiveStep(
                    <Card element="article">
                        <Layout>
                            <SeguroAutomovilStandAlone></SeguroAutomovilStandAlone>
                        </Layout>
                    </Card>
                );
                break;
            case StepsNameEnum.DATOSCLIENTE:
                setActiveStep(
                    <Card element="article">
                        <Layout>
                            <Clientinfo></Clientinfo>
                        </Layout>
                    </Card>
                );
                break;
            case StepsNameEnum.RESUMEN:
                setActiveStep(<Resume></Resume>);
                break;
            default:
                break;
        }
    }, [stepSelected]);

    useEffect(() => {
        if (stepSelected?.name === StepsNameEnum.MANTENIMIENTO && vehicle) {
            if (vehicle?.Type?.Key === "VO")
                setActiveStep(
                    <Card element="article">
                        <Layout>
                            <MantenimientoStandAloneVO></MantenimientoStandAloneVO>
                        </Layout>
                    </Card>
                );
            else setActiveStep(<MantenimientoStandAlone></MantenimientoStandAlone>);

            dispatch(setIsCalculating(false));
        }
    }, [vehicle]);

    return (
        <>
            <Wizard
                collapseBreakpoint="s"
                collapsedCounter={`0/${steps?.length}`}
                collapsedText="Current Step"
                noInteraction
            >
                {showSimulator && (
                    <Wizard.Item key="step_simulador" done>
                        Simulador
                    </Wizard.Item>
                )}
                {steps?.map((step, index) => (
                    <Wizard.Item
                        key={`step_${step.Name}`}
                        active={stepSelected?.index === index}
                        done={stepSelected?.index > index}
                    >
                        {step.Name}
                    </Wizard.Item>
                ))}
            </Wizard>
            <Layout equal>
                <Layout.Item>{activeStep}</Layout.Item>
            </Layout>
        </>
    );
};
