import React, { useEffect, useState } from "react";
import {
  Layout,
  FormField,
  Input,
  DatePicker,
  Select,
} from "@vwfs-bronson/bronson-react";
import moment from "moment";

import "moment/locale/es";

const region = "es";
moment.locale(region);
import { useDispatch } from "react-redux";
import { getProvinceAndMunicipalityByZipCode } from "../../../../actions/locationAction";
import { useLocation } from "../../../../hooks/useLocation";
import { validateEmptyField } from "../../../../validators/validateEmptyField";

import { validateZipCode } from "../../../../validators/validateZipCode";
import {
  spainIdType,
  validateSpanishID,
} from "../../../../validators/validateSpanishID";
import { validateBirthDay } from "../../../../validators/validateBirthDay";
import { validateLicenceDate } from "../../../../validators/validateLicenceDate";
import { checkIfIsNIE } from "../../../../helpers/checkIfIsNIE";
import { checkIfIsDNI } from "../../../../helpers/checkIfIsDNI";

export const TomadorInfo = ({
  formValues,
  handleOnChange,
  setValues,
  isDirty,
}) => {
  const dispatch = useDispatch();
  const { Countries } = useLocation();
  const [listCountries, setListCountries] = useState(Countries);
  useEffect(async () => {
    if (checkIfIsDNI(formValues.NIF)) {
      setListCountries(Countries?.filter((x) => x.CountryCode === "ESP"));
      return;
    }

    if (checkIfIsNIE(formValues.NIF)) {
      setListCountries(Countries?.filter((x) => x.CountryCode !== "ESP"));
      return;
    }

    setListCountries(Countries);
  }, [formValues.NIF]);

  useEffect(() => {
    if (listCountries.length == 1)
      setValues({
        ...formValues,
        Nacionality: listCountries[0].CountryCode,
      });
    else
      setValues({
        ...formValues,
        Nacionality: "",
      });
  }, [listCountries]);

  useEffect(async () => {
    if (validateZipCode(formValues.CodigoPostal)) {
      const info = await getProvinceAndMunicipalityByZipCode(
        formValues.CodigoPostal,
        dispatch
      );
      if (info !== null && info !== undefined) {
        //Update province and municipe fields
        setValues({
          ...formValues,
          Provincia: info.ProvinceDescription,
          Poblacion: info.MunicipalityDescription,
          IdProvince: info.ProvinceCode,
          IdPoblacion: info.MunicipalityCode,
        });
      }
    }
  }, [formValues.CodigoPostal]);

  useEffect(async () => {
    if (validateSpanishID(formValues.NIF)) {
      setValues({
        ...formValues,
        TipoDocumento: spainIdType(formValues.NIF),
      });
    }
  }, [formValues.NIF]);
  return (
    <>
      <Layout>
        <Layout.Item default="1/4" xs="1/1">
          <FormField labelForId="NIF" labelText="NIF/NIE*" type="input">
            <Input
              id="NIF"
              name="NIF"
              placeholder="NIF/NIE*"
              autoComplete="nope"
              value={formValues.NIF.toUpperCase()}
              error={!validateSpanishID(formValues.NIF) && isDirty}
              style={{ textTransform: "uppercase" }}
              onChange={(e) => {
                handleOnChange({
                  target: { value: e.target.value.toUpperCase(), name: "NIF" },
                });
              }}
            />
          </FormField>
        </Layout.Item>
        <Layout.Item default="1/4" xs="1/1">
          <FormField
            labelForId="Nacionality"
            labelText="Nacionalidad*"
            type="select"
            noFloatingLabel
          >
            <Select
              id="Nacionality"
              name="Nacionality"
              autoComplete="nope"
              value={formValues.Nacionality}
              error={!validateEmptyField(formValues.Nacionality) && isDirty}
              onChange={handleOnChange}
              disabled={listCountries.length === 1}
            >
              <Select.Item value="">Seleccionar el país</Select.Item>
              {listCountries.map((country) => (
                <Select.Item
                  key={country.CountryCode}
                  value={country.CountryCode}
                >
                  {country.CountryName}
                </Select.Item>
              ))}
            </Select>
          </FormField>
        </Layout.Item>
        <Layout.Item default="1/4" m="1/2" xs="1/1">
          <FormField
            labelForId="FechaNacimiento"
            labelText="Fecha de Nacimiento*"
            type="other"
          >
            <DatePicker
              dateFormat="d/m/Y"
              maxDate={moment().subtract(18, "y").toDate()}
              id="FechaNacimiento"
              name="FechaNacimiento"
              autoComplete="nope"
              error={
                (!validateBirthDay(formValues.FechaNacimiento) ||
                  !validateEmptyField(formValues.FechaNacimiento)) &&
                isDirty
              }
              value={formValues.FechaNacimiento}
              onChange={(e) =>
                handleOnChange({
                  target: { value: e, name: "FechaNacimiento" },
                })
              }
              onBlur={(e) => {
                handleOnChange({
                  target: { value: e, name: "FechaNacimiento" },
                });
              }}
              locale={region}
            />
          </FormField>
        </Layout.Item>
        <Layout.Item default="1/4" m="1/2" xs="1/1">
          <FormField
            labelForId="FechaCarne"
            labelText="Fecha de Carnet*"
            type="other"
          >
            <DatePicker
              dateFormat="d/m/Y"
              maxDate={moment().toDate()}
              id="FechaCarne"
              name="FechaCarne"
              autoComplete="nope"
              error={
                (!validateLicenceDate(
                  formValues.FechaNacimiento,
                  formValues.FechaCarne
                ) ||
                  !validateEmptyField(formValues.FechaNacimiento)) &&
                isDirty
              }
              value={formValues.FechaCarne}
              onChange={(e) =>
                handleOnChange({ target: { value: e, name: "FechaCarne" } })
              }
              onBlur={(e) => {
                handleOnChange({
                  target: { value: e, name: "FechaCarne" },
                });
              }}
              locale={region}
            />
          </FormField>
        </Layout.Item>
        <Layout.Item default="1/4" m="1/2" xs="1/1">
          <FormField
            labelForId="FechaEfecto"
            labelText="Fecha de Efecto*"
            type="other"
          >
            <DatePicker
              dateFormat="d/m/Y"
              minDate={moment().format("DD/MM/yyyy")}
              id="FechaEfecto"
              name="FechaEfecto"
              autoComplete="nope"
              error={!formValues.FechaEfecto && isDirty}
              value={formValues.FechaEfecto}
              onChange={(e) =>
                handleOnChange({ target: { value: e, name: "FechaEfecto" } })
              }
              onBlur={(e) => {
                handleOnChange({
                  target: { value: e, name: "FechaEfecto" },
                });
              }}
              locale={region}
            />
          </FormField>
        </Layout.Item>
        <Layout.Item default="1/4" m="1/2" xs="1/1">
          <FormField
            labelForId="CodigoPostal"
            labelText="Código Postal*"
            type="input"
          >
            <Input
              id="CodigoPostal"
              placeholder="Código Postal*"
              name="CodigoPostal"
              error={
                (!validateZipCode(formValues.CodigoPostal) ||
                  !validateEmptyField(formValues.CodigoPostal)) &&
                isDirty
              }
              value={formValues.CodigoPostal}
              onChange={handleOnChange}
            />
          </FormField>
        </Layout.Item>
        <Layout.Item default="1/4" m="1/2" xs="1/1">
          <FormField labelForId="Provincia" labelText="Provincia*" type="input">
            <Input
              id="Provincia"
              name="Provincia"
              placeholder="Provincia*"
              error={!validateEmptyField(formValues.Provincia) && isDirty}
              value={formValues.Provincia}
              onChange={handleOnChange}
              readOnly
            />
          </FormField>
        </Layout.Item>
        <Layout.Item default="1/4" m="1/2" xs="1/1">
          <FormField labelForId="Poblacion" labelText="Población*" type="input">
            <Input
              id="Poblacion"
              name="Poblacion"
              placeholder="Población*"
              error={!validateEmptyField(formValues.Poblacion) && isDirty}
              value={formValues.Poblacion}
              onChange={handleOnChange}
              readOnly
            />
          </FormField>
        </Layout.Item>
      </Layout>
    </>
  );
};
