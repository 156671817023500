import { postRequest } from "../config/axiosClient.js";

class OfferSeguroAutoLongDriveService {
    constructor() {
        this.baseUrl = "OfferSeguroAutoLongDriveService";
    }
    async saveSeguroAutoStandAloneOffer(body) {
        return await postRequest(`${this.baseUrl}/SaveSeguroAutoStandAloneOffer`, body);
    }
}

export default OfferSeguroAutoLongDriveService;
