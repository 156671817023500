import { types } from "../types/types";

export const initialState = {
    services: null,
    loading: false,
};

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.serviceSetServices:
            return {
                ...state,
                services: { ...action.payload },
                loading: false,
            };
        case types.serviceSetLoading:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default serviceReducer;
