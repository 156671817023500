import LocationService from "../services/LocationService";
import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";

export const setGetAllCountries = (value) => ({
    type: types.locationGetAllCountries,
    payload: value,
});

export const getAndSetAllCountries = () => {
    return async (dispatch) => {
        let locationService = new LocationService();

        const { data, status, errors } = await locationService.getAllCountries();

        if (status === 200) {
            dispatch(setGetAllCountries(data));
        } else {
            dispatch(addNotificationError(errors));
        }
    };
};

export const setGetAllStreetTypes = (value) => ({
    type: types.locationGetAllStreetTypes,
    payload: value,
});

export const getAndSetAllStreetTypes = () => {
    return async (dispatch) => {
        let locationService = new LocationService();

        const { data, status, errors } = await locationService.getAllStreetsTypes();

        if (status === 200) {
            dispatch(setGetAllStreetTypes(data));
        } else {
            dispatch(addNotificationError(errors));
        }
    };
};

export const getProvinceAndMunicipalityByZipCode = async (zipCode, dispatch) => {
    let locationService = new LocationService();

    const { data, status, errors } = await locationService.getProvinceAndMunicipalityByZipCode({
        ZipCode: zipCode,
    });

    if (status === 200) {
        return data;
    } else {
        dispatch(addNotificationError(errors));
    }
};
