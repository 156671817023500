import React, { useContext } from "react";
import { Layout, CompareAndSelect } from "@vwfs-bronson/bronson-react";
import { SVGDesgaste } from "../../Svgs/SVGDesgaste";
import { SVGMantenimiento } from "../../Svgs/SVGMantenimiento";
// import { percentageDifference } from "../../../helpers/percentageDifference";
import { MantenimientoStandAloneShowMore } from "./MantenimientoStandAloneShowMore";
import { useAddictionalProducts } from "../../../hooks/useAddictionalProducts";
import { useDispatch } from "react-redux";
import { setSelectedMantenimentoStandAlone } from "../../../actions/summaryAction";
import { useEffect } from "react";
import { getMantenimientoLDStandAlone, setMantenimientoStandAlone } from "../../../actions/addictionalProductsAction";
import { validateMantenimientoIcon } from "../../../validators/validateMantenimientoIcon";
import { validateDesgasteIcon } from "../../../validators/validateDesgasteIcon";
import { WizardConext } from "../../../contexts/WizardContext";
import { currencyFormat } from "../../../helpers/currencyFormat";
import { financialFormat } from "../../../helpers/financialFormat";

export const MantenimientoStandAlone = () => {
    const { mantenimientoStandAlone } = useAddictionalProducts();
    const { OutOfWindowAction } = useContext(WizardConext);
    const dispatch = useDispatch();

    const removeSelected = ({ ParentCode }) => {
        mantenimientoStandAlone?.MantenimientoLD?.Mantenimientos.forEach((x) => {
            if (!ParentCode) {
                const value = document.getElementsByName("button-group-item-MT_" + x.Codigo);
                value.forEach((v) => (v.checked = false));
            }
            if (x.Codigo !== ParentCode) {
                const value = document.getElementsByName("button-group-item-MT_" + x.Codigo);
                value.forEach((v) => (v.checked = false));
            }

            Object.keys(x.Paquetes).forEach(function (key) {
                x.Paquetes[key].Selected = false;
            });
        });
    };

    const setSelected = async (paq) => {
        removeSelected(paq);
        let mantSelected = mantenimientoStandAlone?.MantenimientoLD?.Mantenimientos.filter(
            (x) => x.Codigo == paq.Codigo
        );
        const index = mantSelected[0].Paquetes.findIndex((x) => x.Codigo === paq.Codigo && x.Key == paq.Key);

        mantSelected[0].Paquetes[index].Selected = true;
        dispatch(setSelectedMantenimentoStandAlone(mantSelected[0]));
        // TODO: Mirar que es necesario dispatch(checkClientInfotStep());
        window.scrollTo(0, document.getElementById("NextButton").getBoundingClientRect().top);
    };
    const GetItem = (Paq) => {
        if (Paq?.Aportacion?.NumeroCuotasAportacion > 0)
            return (
                <CompareAndSelect.Item
                    key={Paq.Key}
                    buttonLabel="Seleccionar"
                    description={
                        <>
                            <p className="u-mb-none u-text-center">
                                <span className="u-font-size-fs0">
                                    Durante los primeros {Paq?.Aportacion?.NumeroCuotasAportacion} meses:{" "}
                                    {currencyFormat(Paq?.Aportacion?.ImporteDescuentoCuotasAportadas / 100)}/mes
                                </span>
                                <br />
                                <span className="u-font-size-fs0">
                                    Después <b>{currencyFormat(Paq?.Servicio?.ImporteBrutoTarifa / 100)}</b>/mes
                                </span>
                                <br />
                                IVA Incluido
                            </p>
                        </>
                    }
                    discountOldPrice={currencyFormat(Paq?.Servicio?.ImporteBrutoTarifa / 100)}
                    // discountValue={financialFormat(Paq?.Aportacion?.PorcentajeAportacion) + "%"}
                    price={
                        <h4 className="u-text-center u-m-none">
                            {currencyFormat(Paq?.Aportacion?.ImporteDescuentoCuotasAportadas / 100)}
                        </h4>
                    }
                    pricingHeader="Cuota Mensual"
                    title={`< ${financialFormat(Paq.Key, 0)} km/año`}
                />
            );
        else
            return (
                <CompareAndSelect.Item
                    buttonLabel="Seleccionar"
                    description={
                        <>
                            <p className="u-mb-none u-text-center">IVA Incluido</p>
                        </>
                    }
                    price={
                        <h4 className="u-text-center u-m-none">
                            {currencyFormat(Paq?.Servicio?.ImporteBrutoTarifa / 100)}
                        </h4>
                    }
                    pricingHeader="Cuota Mensual"
                    title={`< ${financialFormat(Paq.Key, 0)} km/año`}
                />
            );
    };

    useEffect(() => {
        dispatch(setMantenimientoStandAlone(null));
        dispatch(setSelectedMantenimentoStandAlone(null));
        dispatch(getMantenimientoLDStandAlone(OutOfWindowAction.formOutOfWindow));
    }, []);

    return (
        <>
            {mantenimientoStandAlone?.length > 0 &&
                mantenimientoStandAlone[0]?.internalValue === "No existen aportaciones para el paquete actual" && (
                    <Layout.Item>
                        <p>No existen aportaciones para el paquete actual</p>
                    </Layout.Item>
                )}
            {mantenimientoStandAlone?.MantenimientoLD?.Mantenimientos?.map((MT, index) => (
                <Layout.Item key={"MT_" + MT.Codigo}>
                    {index > 0 && <hr className="u-mb" />}
                    <Layout>
                        <Layout.Item>
                            <Layout className="row" middle>
                                <Layout.Item default="3/5" m="1/1">
                                    <h4 className="u-text-left u-mb-none">{MT.Descripcion}</h4>
                                </Layout.Item>
                                <Layout.Item default="2/5" m="1/1">
                                    <div className="u-text-right">
                                        {validateMantenimientoIcon(MT.Codigo) && <SVGMantenimiento></SVGMantenimiento>}
                                        {validateDesgasteIcon(MT.Codigo) && (
                                            <span className="u-ml">
                                                <SVGDesgaste></SVGDesgaste>
                                            </span>
                                        )}
                                    </div>
                                </Layout.Item>
                            </Layout>
                        </Layout.Item>

                        <Layout.Item default="1/1" l="1/1">
                            <CompareAndSelect
                                inputGroup="group1"
                                onChange={(e) => {
                                    setSelected(MT.Paquetes[e]);
                                }}
                            >
                                {MT.Paquetes?.map((Paq) => GetItem(Paq))}
                            </CompareAndSelect>
                            {/* </ButtonGroup> */}
                        </Layout.Item>
                        <MantenimientoStandAloneShowMore code={MT.Codigo} />
                    </Layout>
                </Layout.Item>
            ))}
        </>
    );
};
