import React from "react";

import { FormSection, Layout, Form } from "@vwfs-bronson/bronson-react";
import { useSummary } from "../../../../hooks/useSummary";
import { ClientResume } from "./Client/ClientResume";
import { DirectionResume } from "./Direction/DirectionResume";
import { OtherInfoResume } from "./OtherInfo/OtherInfoResume";
import { StepsNameEnum } from "../../../../types/enums";

export const ClientInfoResume = () => {
    const { selectedClientStandAlone } = useSummary();
    if (selectedClientStandAlone === undefined || selectedClientStandAlone === null) return null;

    return (
        <Layout.Item className="u-mt-none">
            <FormSection title={StepsNameEnum.DATOSCLIENTE} defaultOpen>
                <Layout equalHeight middle>
                    <Layout.Item>
                        <Form floatingLabel>
                            <React.Fragment key=".0">
                                <ClientResume clientData={selectedClientStandAlone}></ClientResume>
                                <DirectionResume clientData={selectedClientStandAlone}></DirectionResume>
                                <OtherInfoResume clientData={selectedClientStandAlone}></OtherInfoResume>
                            </React.Fragment>
                        </Form>
                    </Layout.Item>
                </Layout>
            </FormSection>
        </Layout.Item>
    );
};
