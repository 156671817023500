import { postRequest } from "../config/axiosClient.js";

export default class NotificationService {
    constructor() {
        this.baseUrl = "notificationservice";
    }

    async getNotificationsByApplication() {
        return await postRequest(`${this.baseUrl}/getnotificationsbyapplication`, {});
    }

    async getNotificationsGrid(obj) {
        return await postRequest(`${this.baseUrl}/readnotificacionsgrid`, obj);
    }

    async createNotification(obj) {
        return await postRequest(`${this.baseUrl}/createnotification`, obj);
    }

    async updateNotification(obj) {
        return await postRequest(`${this.baseUrl}/updateNotification`, obj);
    }
}
