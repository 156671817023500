export default class LocalStorageService {
    getToken() {
        var res = localStorage.getItem("FSOV_AUTH");
        if (res) return JSON.parse(res);
        return null;
    }

    setToken(token) {
        localStorage.setItem("FSOV_AUTH", JSON.stringify(token));
    }

    removeToken() {
        localStorage.removeItem("FSOV_AUTH");
    }
}
