import { types } from "../types/types";

export const initialState = {
    campains: [],
    loading: false,
};

const campainReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.campainSetCampains:
            return {
                ...state,
                campains: [...action.payload],
                loading: false,
            };
        case types.campainSetLoading:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default campainReducer;
