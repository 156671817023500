export const divideArrayInParts = (array, parts) => {
    const result = []; //we create it, then we'll fill it

    for (let index = 0; index < parts; index++) {
        result.push([]);
    }

    const wordsPerLine = Math.ceil(array.length / parts);

    for (let line = 0; line < parts; line++) {
        for (let i = 0; i < wordsPerLine; i++) {
            const value = array[i + line * wordsPerLine];
            if (!value) continue;
            result[line].push(value);
        }
    }

    return result;
};
