import { validateEmptyField } from "./validateEmptyField";
import { validateSpanishID } from "./validateSpanishID";

export const validateClientInfo = (selectedClientStandAlone) => {
    if (
        validateEmptyField(selectedClientStandAlone?.Name) &&
        validateEmptyField(selectedClientStandAlone?.Surname1) &&
        validateEmptyField(selectedClientStandAlone?.Surname2) &&
        validateSpanishID(selectedClientStandAlone?.NIF) &&
        validateEmptyField(selectedClientStandAlone?.Nacionality) &&
        validateEmptyField(selectedClientStandAlone?.PhoneNumber) &&
        validateEmptyField(selectedClientStandAlone?.Email) &&
        validateEmptyField(selectedClientStandAlone?.EmailConfirmation) &&
        selectedClientStandAlone?.Email === selectedClientStandAlone?.EmailConfirmation &&
        selectedClientStandAlone.GRPD
    ) {
        return true;
    } else return false;
};
