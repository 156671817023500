import { types } from "../types/types";

export const initialState = {
    products: [],
    loading: false,
    error: null,
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.productSetProducts:
            return {
                ...state,
                products: [...action.payload],
                loading: false,
            };
        case types.productSetLoading:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
