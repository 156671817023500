import React from "react";
import {
  Layout,
  FormField,
  Input,
  Heading,
  Fieldset,
  Checkbox,
} from "@vwfs-bronson/bronson-react";
import { WizardConext } from "../../../../contexts/WizardContext";
import { useContext } from "react";

export const OtherInfo = ({ formValuesClient, setHandleOnChangeClient }) => {
  const { isDirty } = useContext(WizardConext);

  return (
    <>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/1">
            <Heading level={5} className="u-text-left u-mt u-mb-none">
              Otros Datos
            </Heading>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" xs="1/1">
            <FormField
              labelForId="AssociatedPersonToOffer"
              labelText="Persona asociada a la oferta"
              type="input"
            >
              <Input
                id="AssociatedPersonToOffer"
                name="AssociatedPersonToOffer"
                placeholder="Persona asociada a la oferta"
                autoComplete="nope"
                defaultValue={formValuesClient.AssociatedPersonToOffer}
                onChange={setHandleOnChangeClient}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/1" xs="1/1">
            {formValuesClient.GRPD ? (
              <Checkbox
                id="GRPD"
                name="GRPD"
                defaultValue={formValuesClient.GRPD}
                defaultChecked
                onChange={(e) =>
                  setHandleOnChangeClient({
                    target: { value: e.target.checked, name: "GRPD" },
                  })
                }
                error={!formValuesClient.GRPD && isDirty}
                className="u-mt-large"
              >
                <Heading level={6} className="u-m-none">
                  He informado al cliente sobre sus derechos a efectos del GDPR.
                  Para más información pulse <a href="#0">aquí</a>
                </Heading>
              </Checkbox>
            ) : (
              <Checkbox
                id="GRPD"
                name="GRPD"
                defaultValue={formValuesClient.GRPD}
                onChange={(e) =>
                  setHandleOnChangeClient({
                    target: { value: e.target.checked, name: "GRPD" },
                  })
                }
                error={!formValuesClient.GRPD && isDirty}
                className="u-mt-large"
              >
                <Heading level={6} className="u-m-none">
                  He informado al cliente sobre sus derechos a efectos del GDPR.
                  Para más información pulse <a href="#0">aquí</a>
                </Heading>
              </Checkbox>
            )}
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
    </>
  );
};
