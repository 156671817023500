import React from "react";

import { Layout, RadioButton, Table } from "@vwfs-bronson/bronson-react";

import { financialFormat } from "../../../../helpers/financialFormat";
import { calculateHostDiscount } from "../../../../helpers/calculateHostDiscount";

export const Coberturas = ({ tarifasZurich, setCobertura }) => {
    if (tarifasZurich == null || tarifasZurich.length == 0) return <></>;
    if (tarifasZurich[0].internalValue)
        return (
            <Layout.Item>
                <p>
                    {tarifasZurich[0].internalValue === "No existen aportaciones para el paquete actual"
                        ? tarifasZurich[0].internalValue
                        : "Ocurrió un error al obtener las tarifas"}
                </p>
            </Layout.Item>
        );
    const TieneAportacion =
        tarifasZurich.filter((x) => x.grupoPaquetes[0]?.Aportaciones[0]?.NumeroCuotasAportacion > 0).length > 0
            ? true
            : false;

    if (!tarifasZurich || tarifasZurich.length === 0) {
        return (
            <Layout.Item>
                <p>No ha sido posible localizar coberturas.</p>
            </Layout.Item>
        );
    }
    return (
        <Layout.Item>
            <Table dataControls>
                <React.Fragment key=".0">
                    <Table.Caption>Description of table content.</Table.Caption>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Paquete Comercial</Table.Th>
                            <Table.Th>Cuota Mensual</Table.Th>
                            {TieneAportacion && <Table.Th>Oferta Comercial</Table.Th>}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {tarifasZurich.map((tar) => (
                            <Table.Tr key={tar.grupoPaquetes[0]?.Codigo}>
                                <Table.Td>
                                    <RadioButton name="radio-button-sample" onChange={() => setCobertura(tar)}>
                                        {tar.ServiceName}
                                    </RadioButton>
                                </Table.Td>
                                <Table.Td>
                                    {financialFormat(tar.grupoPaquetes[0]?.Paquetes[0]?.ImporteNetoTarifa)} €/mes
                                </Table.Td>
                                {TieneAportacion && (
                                    <Table.Td>
                                        {tar.grupoPaquetes[0]?.Aportaciones[0]?.NumeroCuotasAportacion} meses por{" "}
                                        {calculateHostDiscount(
                                            tar.grupoPaquetes[0]?.Aportaciones[0]?.ImporteNetoTarifa,
                                            tar.grupoPaquetes[0]?.Aportaciones[0]?.PorcentajeAportacion
                                        )}
                                        {" €/mes"}
                                    </Table.Td>
                                )}
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </React.Fragment>
            </Table>
        </Layout.Item>
    );
};
