export const adapterAddSimulationsInSimulationsByFam = (Simulations, NewSimulation) => {
    if (!NewSimulation?.ProdFam) return Simulations;

    var ProdFam = NewSimulation.ProdFam.Key === "RNT" ? NewSimulation.ProdFam.Key : NewSimulation.Product.Key;

    var groupIndex = Simulations.findIndex((x) => x.ProdFam === ProdFam);
    if (groupIndex === -1) {
        var ProdFamName = NewSimulation.Product.Value;
        Simulations.push({
            ProdFam,
            ProdFamName,
            Simulations: [NewSimulation],
        });
    } else {
        Simulations[groupIndex].Simulations.push(NewSimulation);
    }
    return Simulations;
};
