import axios from "axios";
import { outSession } from "../actions/authAction";
import { adapterApiGatewayProxyResponseToObject } from "../adapters/adapterApiGatewayProxyResponseToObject";
import LocalStorageService from "../services/LocalStorageService";
import { store } from "../store/store";

export const axiosClient = axios.create();

const localStorageService = new LocalStorageService();

axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axiosClient.defaults.headers = {
    "Content-Type": "application/json",
};

export function getRequest(URL) {
    return axiosClient.get(`/${URL}`).then((response) => response);
}
export function postRequest(URL, payload) {
    return axiosClient
        .post(`/${URL}`, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error?.response) return error.response;
            return error;
        });
}
export function getRequestWithHeadersSalesAssist(URL, headers) {
    const axiosClient = axios.create();
    axiosClient.defaults.headers = {
        "Content-Type": "application/json",
    };
    return axiosClient
        .get(`${URL}`, {
            headers: headers,
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error?.response) return error.response;
            return error;
        });
}

export function patchRequest(URL, payload) {
    return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
    return axiosClient.delete(`/${URL}`).then((response) => response);
}

axiosClient.interceptors.request.use((request) => {
    if (request.url.includes("/fsoneviewservice/checkout")) {
        return request;
    }

    const token = localStorageService.getToken();

    if (!token) {
        store.dispatch(outSession());
        throw new Error("Unauthorize");
    }

    request.headers.Authorization = "Bearer " + token.AccessToken;
    return request;
});

axiosClient.interceptors.response.use(
    function (response) {
        return adapterApiGatewayProxyResponseToObject(response);
    },
    async function (error) {
        if (error.response?.status === 401) {
            const token = localStorageService.getToken();

            if (!token) {
                store.dispatch(outSession());
            }

            var { status, data } = await postRequest("authenticationservice/refreshtoken", token);

            if (status === 200) {
                localStorageService.setToken(data);
                error.config.headers["Authorization"] = "Bearer " + data.AccessToken;
                return axiosClient.request(error.config);
            } else {
                store.dispatch(outSession());
            }
        }
        return Promise.reject(error);
    }
);
