export const adapterSimulationsByFam = (Simulations) => {
    let res = [];
    if (!Simulations || Simulations.length < 0) {
        return res;
    }
    Simulations.forEach((sim) => {
        var ProdFam = sim.ProdFam.Key === "RNT" ? sim.ProdFam.Key : sim.Product.Key;
        var indexSearch = res.findIndex((x) => x.ProdFam === ProdFam);
        if (indexSearch === -1) {
            var ProdFamName = sim.Product.Value;
            res.push({ ProdFam, ProdFamName, Simulations: [sim] });
        } else {
            res[indexSearch].Simulations.push(sim);
        }
    });
    return res;
};
