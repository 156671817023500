export const adapterInternalError = (error = "") => {
    let userError = " ";
    const startIndex = error.indexOf("Error: ");
    const endIndex = error.indexOf(" con el body");

    if (startIndex === -1 && endIndex === -1) return "";

    userError = error.substring(startIndex + 7, endIndex);

    return userError;
};
