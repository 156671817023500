import * as planType from "../store/actions/actionTypes/planActionTypes";
import Utility from "../services/UtilitiesService";
import { handleError, loading, setSelected } from "../store/reducers/commonHandlers";

export const initialState = {
    plans: [],
    selected: null,
    isPlan: false,
};

const getplans = (state, action) => {
    return Utility.updateObjectReducer(state, {
        plans: action.payload,
        loading: false,
    });
};

const setIsPlan = (state, action) => {
    return Utility.updateObjectReducer(state, {
        isPlan: action.payload,
    });
};

export const planReducer = (state = initialState, action) => {
    switch (action.type) {
        case planType.PLAN_LOADING:
            return loading(state, action);
        case planType.PLAN_ERROR:
            return handleError(state, action);
        case planType.PLAN_GETPLANS:
            return getplans(state, action);
        case planType.PLAN_SET_ISPLAN:
            return setIsPlan(state, action);
        case planType.PLAN_SET_SELECTED:
            return setSelected(state, action);
        default:
            return state;
    }
};
