import { postRequest } from "../config/axiosClient.js";
import AppService from "./AppService.js";
import UtilitiesService from "./UtilitiesService.js";
class DocumentsService {
    constructor() {
        this.baseUrl = "documentsservice";
    }

    async downloadSimulationsPDF(body) {
        let dataurl = await UtilitiesService.urlToBase64ImgPromise(body);
        return AppService.post(`${this.baseUrl}/downloadSimulationsPDF`, dataurl, null, "core").then((res) => {
            if (res.body) {
                if (window.navigator.msSaveOrOpenBlob) {
                    let blobObject = UtilitiesService.b64toBlob(res.body, "application/pdf");
                    window.navigator.msSaveOrOpenBlob(blobObject, "ofertaVWFS.pdf");
                } else {
                    const blob = UtilitiesService.b64toBlob(res.body, "application/pdf");
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                }
            }
        });
    }

    async pruebaPDF(body) {
        return AppService.post(`${this.baseUrl}/pruebapdf`, body, null, "core").then((res) => {
            if (res.body) {
                if (window.navigator.msSaveOrOpenBlob) {
                    let blobObject = UtilitiesService.b64toBlob(res.body, "application/pdf");
                    window.navigator.msSaveOrOpenBlob(blobObject, "ofertaVWFS.pdf");
                } else {
                    const blob = UtilitiesService.b64toBlob(res.body, "application/pdf");
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                }
            }
        });
    }

    async getTemplates(obj) {
        return await postRequest(`${this.baseUrl}/readtemplatesgrid`, obj);
    }

    async generateCreditRequestDocument(request) {
        return await postRequest(`${this.baseUrl}/GenerateCreditRequestDocument`, request);
    }
}

export default DocumentsService;
