import LocalStorageService from "../services/LocalStorageService";
import { types } from "../types/types";

var localStorageService = new LocalStorageService();

export const outSession = () => {
    return (dispatch) => {
        localStorageService.removeToken();

        dispatch({
            type: types.authLogOut,
        });
        dispatch({
            type: types.authError,
            payload: "Unauthorize",
        });
    };
};

const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    return JSON.parse(window.atob(base64Url));
};

export const getIsAuthenticated = (token) => {
    return (dispatch) => {
        try {
            var tokenJWT = JSON.parse(window.atob(token));
            dispatch(
                authenticate({
                    AccessToken: tokenJWT.AccessToken,
                    RefreshToken: tokenJWT.RefreshToken,
                    AccessTokenExpiration: tokenJWT.AccessTokenExpiration,
                })
            );
        } catch (err) {
            dispatch({
                type: types.authError,
                payload: "Unauthorize",
            });
        }
    };
};

export const authenticate = ({ AccessToken, RefreshToken, AccessTokenExpiration }) => {
    return (dispatch) => {
        try {
            localStorageService.removeToken();
            if (!AccessToken || !RefreshToken || !AccessTokenExpiration) throw new Error("no habilitado");
            localStorageService.setToken({ AccessToken, RefreshToken, AccessTokenExpiration });
            dispatch(updateAuthenticationData({ AccessToken, RefreshToken }));
        } catch (err) {
            dispatch({
                type: types.authError,
                payload: "Unauthorize",
            });
        }
    };
};

const updateAuthenticationData = ({ AccessToken, RefreshToken }) => {
    return (dispatch) => {
        try {
            if (AccessToken && RefreshToken) {
                const jwt = parseJwt(AccessToken);
                if (jwt.data) {
                    dispatch({
                        type: types.authSetIsAuthenticated,
                        payload: true,
                    });
                }
            } else {
                dispatch({
                    type: types.authError,
                    payload: "Unauthorize",
                });
            }
        } catch (e) {
            dispatch({
                type: types.authError,
                payload: "Unauthorize",
            });
        }
    };
};

export const checkIsAuthenticated = () => {
    return (dispatch) => {
        const token = localStorageService.getToken();

        if (!token) {
            dispatch({
                type: types.authError,
                payload: "Unauthorize",
            });
            return;
        }

        const { AccessToken, RefreshToken } = token;

        dispatch({
            type: types.authSetIsAuthenticated,
            payload: !!AccessToken && !!RefreshToken,
        });
    };
};
