import UtilitiesService from "../services/UtilitiesService";
import { handleError } from "../store/actions/commonAction";
import { types } from "../types/types";

export const initialState = {
    sessionOut: false,
    isAuthenticated: false,
    user: "",
    pathname: localStorage.getItem("url") ?? "/simulator",
    error: null,
};

const setOutOfSession = (state) => {
    return UtilitiesService.updateObjectReducer(state, {
        sessionOut: true,
        isAuthenticated: false,
        user: "",
    });
};

const setIsAuthenticated = (state, action) => {
    return UtilitiesService.updateObjectReducer(state, {
        isAuthenticated: action.payload,
        loading: false,
    });
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.authError:
            return handleError(state, action);
        case types.authSetIsAuthenticated:
            return setIsAuthenticated(state, action);
        case types.authLogOut:
            return setOutOfSession(state, action);
        default:
            return state;
    }
};
