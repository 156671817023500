import { Modal, Layout } from "@vwfs-bronson/bronson-react";
import { useWizard } from "../../hooks/useWizard";
export const ModalInfoStandAlone = () => {
    const { showModalInfoStandAlone, setShowModalInfoStandAlone } = useWizard();
    const onConfirm = () => {
        setShowModalInfoStandAlone(false);
    };
    if (!showModalInfoStandAlone) return null;
    return (
        <Layout.Item>
            <Modal
                buttonConfirmText="Aceptar"
                buttonConfirmType="submit"
                id="ModalInfoStandAlone"
                onConfirm={onConfirm}
                shown={showModalInfoStandAlone}
                hideCloseButton
            >
                Todavía estás a tiempo de ofrecer a tu cliente servicios de mantenimiento y/o seguro de auto Long
                Drive/Fulldrive no vinculados a la financiación (modalidad Stand Alone)
            </Modal>
        </Layout.Item>
    );
};
