import { postRequest } from "../config/axiosClient.js";

class AdditionalProductsService {
    constructor() {
        this.baseUrl = "additionalproductsservice";
    }
    async getAdditionalProducts(body) {
        return await postRequest(`${this.baseUrl}/readproductosadicionales`, body);
    }
    async getAdditionalProductsData(body) {
        return await postRequest(`${this.baseUrl}/readproductosadicionalesdata`, body);
    }
    async getMantenimientoLDStandAlone(body) {
        return await postRequest(`${this.baseUrl}/readmantenimientoldstandalone`, body);
    }

    async identifyMantenimientoLD(body) {
        return await postRequest(`${this.baseUrl}/identifymantenimientold`, body);
    }

    async identifySeguroAutomovil(body) {
        return await postRequest(`${this.baseUrl}/identifyseguroautomovil`, body);
    }

    async identifyPerdidaTotal(body) {
        return await postRequest(`${this.baseUrl}/identifyperdidatotal`, body);
    }

    async identifyPagoProtegido(body) {
        return await postRequest(`${this.baseUrl}/identifysegurocredito`, body);
    }

    async identifyRetiradaCarnet(body) {
        return await postRequest(`${this.baseUrl}/identifyretiradacarnet`, body);
    }

    async readProductsBenefitsLD(body) {
        return await postRequest(`${this.baseUrl}/readproductsbenefitsld`, body);
    }
}

export default AdditionalProductsService;
